import { queries } from '@constants/queryKeys'
import { IFormType } from '@contracts/models/dynamic'
import { UseQueryType } from '@contracts/query'
import { useQuery } from '@tanstack/react-query'
import * as API from './api'

export const useFormTypes: UseQueryType<IFormType[]> = (options) =>
  useQuery({
    queryKey: queries.dynamic.form_type_list.queryKey,
    queryFn: API.getAllFormTypes,
    ...options,
  })
