import { AddAddressModal } from '@components/AddAddress'
import AddAddressPopup from '@components/AddAddressPopup'
import GrnBasic from '@components/grn/GrnBasic'
import GrnDetails from '@components/grn/GrnDetails'
import { IOrg } from '@contracts/models/org'
import {
  useCreateGrn,
  useCreateGrnStatus,
  useDeleteStockEntry,
  useGrnBillNumberInfo,
  useStockData,
} from '@services/grn'
import { useCreateOrgAddress, useOrgUserInfo } from '@services/org'
import {
  useGetVendor,
  useUpdateVendor,
  useVendorListInfo,
} from '@services/vendor'
import { Divider, Form, message, Popconfirm } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { yoloInventoryLogo } from 'yolo-design/assets'
import { Button, FormWrapper, Modal } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'

const GrnInfo = () => {
  const [inputForm] = Form.useForm()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const { orgId } = useParams()
  const [addAddress, setAddAddres] = useState(false)
  const [itemData, setItemData] = useState<any>()
  const [isAddressPopup, setAddressPopup] = useState(true)
  const [popupType, setPopupType] = useState('')
  const { data: userInfo, refetch: OrgRefetch } = useOrgUserInfo()
  const [error, setError] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const billId = searchParams.get('billId')
  const [dataSource, setDataSource] = useState<any>([
    {
      unique_id: 'empty-object',
    },
  ])
  const orgInfo: any = userInfo?.organization?.find(
    (el: IOrg) => String(el?.id) === String(orgId)
  )
  const [selectVendor, setSelectVendor] = useState()
  const tab = searchParams.get('returnTab') || '1'

  // API CALLS
  const { data: vendorData, refetch: VendorRefetch } = useGetVendor(
    { retry: false, enabled: !!Number(selectVendor) },
    { id: Number(selectVendor) }
  )
  const { data: grnNumber } = useGrnBillNumberInfo({}, { id: Number(tab) })

  const { data: list } = useVendorListInfo(
    {},
    { page_size: 10, search: search }
  )
  const {
    data: stockData,
    refetch: stockDetailsRefetch,
    isLoading: stockDetailsLoading,
  } = useStockData(
    {
      enabled: !!billId,
      staleTime: 0,
    },
    { id: Number(billId) }
  )
  const createGRN = useCreateGrn({
    onSuccess: (res) => {
      if (!billId) {
        searchParams?.set('billId', res?.id)
        setSearchParams(searchParams)
      } else {
        stockDetailsRefetch()
      }
      inputForm.resetFields([
        'gross_weight',
        'net_weight',
        'tare_weight',
        'item_notes',
        'item_id',
        'item',
      ])
      setItemData(undefined)
    },
  })
  const createGRNStatus = useCreateGrnStatus({
    onSuccess: () => {
      navigate(`/${orgId}/stock-entry?returnTab=${tab}`)
    },
  })
  const createOrgAddress = useCreateOrgAddress({
    onSuccess: () => {
      // navigate(`/${orgId}/grn`)
      OrgRefetch()
      setAddAddres(false)
    },
  })
  const createVendorAddress = useUpdateVendor({
    onSuccess: () => {
      // navigate(`/${orgId}/grn`)
      VendorRefetch()
      setAddAddres(false)
    },
  })
  const deleteItem = useDeleteStockEntry({
    onSuccess: () => {
      navigate(`/${orgId}/stock-entry?returnTab=${tab}`)
    },
  })

  const handleFinishPurchase = () => {
    const val = inputForm.getFieldsValue()

    const items = {
      id: val?.item_id ? val?.item_id : val?.item,
      type: '1',
      unit: val?.unit,
      tar_quantity: val?.tare_weight,
      gross_quantity: val?.gross_weight,
      net_quantity: val?.net_weight,
      notes: val?.item_notes,
      batch_no: itemData?.batch_no,
      manufactured_date: itemData?.manufactured_date
        ? dayjs(itemData?.manufactured_date)?.format('YYYY-MM-DD')
        : undefined,
      expiry_date: itemData?.expiry_date
        ? dayjs(itemData?.expiry_date)?.format('YYYY-MM-DD')
        : undefined,
    }

    const grnData = {
      grn_type: tab,
      shift: val?.shift,
      reference_number: val?.reference_no,
      reference_date: dayjs(val?.ref_date).format('YYYY-MM-DD'),
      work_order: [val?.work_order],
      operator: val?.operator,
      grn_number: billId ? stockData?.bill_number : grnNumber?.grn_number,
      vendor: selectVendor || undefined,
      bill_date: dayjs(val?.bill_date).format('YYYY-MM-DD'),
      delivery_date: dayjs(val?.delivery_date).format('YYYY-MM-DD'),
      purchase_orders: val?.purchase_order,
      items: items,
      notes: val?.terms,
    }
    if (
      Number(val?.net_weight) + Number(val?.tare_weight) !==
      Number(val?.gross_weight)
    ) {
      message.error('Gross Qty should be equal to  sum of Net Qty and Tar Qty ')
      setError(true)
    } else if (Number(val?.net_weight) > Number(val?.gross_weight)) {
      message.error('Net Qty should not greaer than Gross Qty ')
    } else if (Number(val?.tare_weight) > Number(val?.gross_weight)) {
      message.error('Tare Qty should not greaer than Gross Qty')
    } else if (Number(val?.tare_weight) > Number(val?.net_weight)) {
      message.error('Tare Qty should not greaer than Net Qty')
    } else {
      createGRN?.mutate({ grnData })
    }
  }
  // const mockSerial = {
  //   requestPort: async () => {
  //     console.log('Mock: Port requested')
  //     return {
  //       open: async ({ baudRate }: { baudRate: number }) => {
  //         console.log(`Mock: Port opened with baud rate ${baudRate}`)
  //       },
  //       readable: {
  //         getReader: () => ({
  //           async read() {
  //             console.log('Mock: Reading data...')
  //             // Simulate reading data by returning a new Uint8Array
  //             const value = new TextEncoder().encode('123.45\n')
  //             return { value, done: false }
  //           },
  //           releaseLock: () => {
  //             console.log('Mock: Reader lock released')
  //           },
  //         }),
  //       },
  //       close: async () => {
  //         console.log('Mock: Port closed')
  //       },
  //       getInfo: () => ({
  //         vendorId: '1234',
  //         productId: '5678',
  //         serialNumber: 'ABCD1234',
  //       }),
  //     }
  //   },
  //   getPorts: async () => {
  //     console.log('Mock: Returning saved ports')
  //     return []
  //   },
  // }

  const isVenAddress = vendorData?.address_detail?.city ? true : false
  const isOrgAddress = orgInfo?.address_detail?.[0]?.city ? true : false
  const billDate = inputForm.getFieldValue('bill_date')
  const disableSaveButton = stockData?.item_details?.length

  useEffect(() => {
    if (billId) {
      inputForm.setFieldsValue({
        bill_no: stockData?.bill_number,
        bill_date: dayjs(stockData?.bill_date),
        delivery_date: dayjs(stockData?.delivery_date),
        reference_no: stockData?.reference_number,
        ref_date: stockData?.reference_date
          ? dayjs(stockData?.reference_date)
          : '',
        terms: stockData?.notes,
        shift: stockData?.shift ? String(stockData?.shift) : '',
        purchase_order: stockData?.purchase_order?.map((el: any) =>
          String(el?.id)
        ),
      })
      setSelectVendor(stockData?.vendor_id)
    }
  }, [stockData])

  return (
    <div>
      <div className="d-flex flex-column w-100 h-100">
        <div className="d-flex w-100 h-max-content py-8 px-24 justify-content-between border-bottom border-1 border-grey-300">
          <img
            className="w-auto cursor-pointer"
            style={{ height: '36px' }}
            src={yoloInventoryLogo}
            onClick={() => navigate(`/${orgId}`)}
            alt="inventory"
          />
          <div className="d-flex gap-8">
            <Button
              antdButtonProps={{
                onClick: () => {
                  navigate(`/${orgId}?returnTab=${tab}`)
                },
              }}
              type="outlined"
              label="Close"
              size="medium"
            />
            {stockData?.bill_status ? (
              <>
                <Popconfirm
                  placement="leftTop"
                  style={{ width: '200px' }}
                  okText="Delete"
                  cancelText="Cancel"
                  okButtonProps={{
                    loading: deleteItem?.isLoading,
                    style: {
                      borderColor: Color?.indicators?.error,
                      backgroundColor: 'transparent',
                      color: Color?.indicators?.error,
                    },
                  }}
                  title="Are you sure to delete this entry?"
                  onConfirm={() => {
                    deleteItem?.mutate({ id: Number(billId) })
                  }}
                  icon={false}
                >
                  <Button
                    type="outlined"
                    label="Delete"
                    size="medium"
                    color={Color?.indicators?.error}
                    borderColor={Color?.indicators?.error}
                  />
                </Popconfirm>
                {/* <Button
                  antdButtonProps={{
                    loading: deleteItem?.isLoading,
                    onClick: () => {
                      deleteItem?.mutate({ id: Number(billId) })
                    },
                  }}
                  type="outlined"
                  label="Delete"
                  size="medium"
                  color={Color?.indicators?.error}
                  borderColor={Color?.indicators?.error}
                /> */}
              </>
            ) : (
              <></>
            )}

            {stockData?.bill_status === 2 || stockData?.bill_status === 3 ? (
              <></>
            ) : (
              <>
                <Button
                  antdButtonProps={{
                    disabled: !disableSaveButton,
                    loading: createGRNStatus?.isLoading,
                    onClick: () => {
                      const val = inputForm.getFieldsValue()

                      if (tab === '1') {
                        if (isOrgAddress) {
                          if (isVenAddress) {
                            createGRNStatus?.mutate({
                              data: {
                                id: billId,
                                data: {
                                  grn_type: tab,
                                  reference_number: val?.reference_no,
                                  reference_date: dayjs(val?.ref_date).format(
                                    'YYYY-MM-DD'
                                  ),
                                  work_order: [val?.work_order],
                                  operator: val?.operator,
                                  grn_number: billId
                                    ? stockData?.bill_number
                                    : grnNumber?.grn_number,
                                  vendor: selectVendor || undefined,
                                  bill_date: dayjs(val?.bill_date).format(
                                    'YYYY-MM-DD'
                                  ),
                                  delivery_date: dayjs(
                                    val?.delivery_date
                                  ).format('YYYY-MM-DD'),
                                  purchase_orders: val?.purchase_order,
                                  notes: val?.terms,
                                  bill_status_id: '1',
                                },
                              },
                            })
                          } else {
                            setPopupType('Vendor')
                            setAddressPopup(isVenAddress)
                          }
                        } else {
                          setPopupType('Organization')
                          setAddressPopup(isOrgAddress)
                        }
                      } else {
                        createGRNStatus?.mutate({
                          data: {
                            id: billId,
                            data: {
                              grn_type: tab,
                              reference_number: val?.reference_no,
                              reference_date: dayjs(val?.ref_date).format(
                                'YYYY-MM-DD'
                              ),
                              work_order: [val?.work_order],
                              operator: val?.operator,
                              grn_number: billId
                                ? stockData?.bill_number
                                : grnNumber?.grn_number,
                              vendor: selectVendor || undefined,
                              bill_date: dayjs(val?.bill_date).format(
                                'YYYY-MM-DD'
                              ),
                              delivery_date: dayjs(val?.delivery_date).format(
                                'YYYY-MM-DD'
                              ),
                              purchase_orders: val?.purchase_order,
                              notes: val?.terms,
                              bill_status_id: '1',
                            },
                          },
                        })
                      }
                    },
                  }}
                  type="outlined"
                  label="Save as Draft"
                  size="medium"
                />
              </>
            )}

            <Button
              antdButtonProps={{
                disabled: !disableSaveButton,
                loading: createGRNStatus?.isLoading,
                onClick: () => {
                  const val = inputForm.getFieldsValue()

                  if (tab === '1') {
                    if (isOrgAddress) {
                      if (isVenAddress) {
                        createGRNStatus?.mutate({
                          data: {
                            id: billId,
                            data: {
                              grn_type: tab,
                              reference_number: val?.reference_no,
                              reference_date: dayjs(val?.ref_date).format(
                                'YYYY-MM-DD'
                              ),
                              work_order: [val?.work_order],
                              operator: val?.operator,
                              grn_number: billId
                                ? stockData?.bill_number
                                : grnNumber?.grn_number,
                              vendor: selectVendor || undefined,
                              bill_date: dayjs(val?.bill_date).format(
                                'YYYY-MM-DD'
                              ),
                              delivery_date: dayjs(val?.delivery_date).format(
                                'YYYY-MM-DD'
                              ),
                              purchase_orders: val?.purchase_order,
                              notes: val?.terms,
                              bill_status_id: '2',
                            },
                          },
                        })
                      } else {
                        setPopupType('Vendor')
                        setAddressPopup(isVenAddress)
                      }
                    } else {
                      setPopupType('Organization')
                      setAddressPopup(isOrgAddress)
                    }
                  } else {
                    createGRNStatus?.mutate({
                      data: {
                        id: billId,
                        data: {
                          grn_type: tab,
                          reference_number: val?.reference_no,
                          reference_date: dayjs(val?.ref_date).format(
                            'YYYY-MM-DD'
                          ),
                          work_order: [val?.work_order],
                          operator: val?.operator,
                          grn_number: billId
                            ? stockData?.bill_number
                            : grnNumber?.grn_number,
                          vendor: selectVendor || undefined,
                          bill_date: dayjs(val?.bill_date).format('YYYY-MM-DD'),
                          delivery_date: dayjs(val?.delivery_date).format(
                            'YYYY-MM-DD'
                          ),
                          purchase_orders: val?.purchase_order,
                          notes: val?.terms,
                          bill_status_id: '2',
                        },
                      },
                    })
                  }
                },
              }}
              type="solid"
              label="Save"
              size="medium"
            />
          </div>
        </div>
        <div className="d-flex p-24 flex-column w-100 h-100">
          <div>
            <FormWrapper
              antdFormProps={{
                onFinish: handleFinishPurchase,
                form: inputForm,
              }}
            >
              <GrnBasic
                vendorData={vendorData}
                setSelectVendor={setSelectVendor}
                setSearch={setSearch}
                dataList={list?.data || []}
                grnNumber={grnNumber?.grn_number}
                selectVendor={Number(selectVendor)}
                form={inputForm}
                stockData={stockData}
              />
              <Divider />
            </FormWrapper>
          </div>
          <div>
            <FormWrapper
              antdFormProps={{
                onFinish: handleFinishPurchase,
                form: inputForm,
              }}
            >
              <GrnDetails
                setError={setError}
                error={error}
                setItemData={setItemData}
                itemData={itemData}
                stockDetailsRefetch={stockDetailsRefetch}
                setDataSource={setDataSource}
                dataSource={dataSource}
                form={inputForm}
                grnNo={grnNumber?.grn_number}
                billDate={billDate}
                stockData={stockData}
                createGRN={createGRN}
                selectVendor={selectVendor}
                stockDetailsLoading={stockDetailsLoading}
              />
            </FormWrapper>
          </div>
        </div>
        <Modal
          antdModalProps={{
            width: '272px',
            onCancel: () => setAddressPopup(true),
            destroyOnClose: true,
          }}
          open={!isAddressPopup}
          title={''}
          okButtonDetails={{}}
          cancelButtonDetails={{}}
        >
          <AddAddressPopup
            setAddAddress={setAddAddres}
            setAddressPopup={setAddressPopup}
            type={popupType}
          />
        </Modal>

        <Modal
          antdModalProps={{ width: '60%', destroyOnClose: true }}
          onCancel={() => setAddAddres(false)}
          open={addAddress}
          title=""
          okButtonDetails={{}}
          cancelButtonDetails={{}}
        >
          <div></div>
          <AddAddressModal
            update={
              popupType === 'Vendor' ? createVendorAddress : createOrgAddress
            }
            setAddress={setAddAddres}
            id={selectVendor}
            isVendor={popupType === 'Vendor' ? true : false}
          />
        </Modal>
      </div>
    </div>
  )
}

export default GrnInfo
