import { IJob, IWorkOrder } from '@contracts/models/grn'
import { Divider } from 'antd'
import React, { FC } from 'react'
import Barcode from 'react-barcode'

type Iprops = {
  data?: any
  billDate?: any
  itemDetails?: any
  tarQty: number
  findUnitName: any
  jobData?: IJob
  workOrderData?: IWorkOrder
  operatorName?: string
  isReturn?: boolean
}

const MR: FC<Iprops> = ({
  data,
  billDate,
  itemDetails,
  findUnitName,
  jobData,
  workOrderData,
  operatorName,
  isReturn = false,
}) => {
  return (
    <>
      <div className="d-flex w-100 justify-content-between">
        <span className="text-label-extra-small fw-medium">
          No: {data?.batch_number}
        </span>
        <span className="text-label-extra-small fw-medium">
          {' '}
          {billDate?.format('DD-MM-YY/hh:MM A')}
        </span>
      </div>
      <div className="d-flex w-100 mt-2 justify-content-between">
        <span className="text-label-extra-small fw-medium text-truncate">
          Job: {jobData?.job_code} ({jobData?.name})
        </span>
      </div>
      {!isReturn && (
        <div className="d-flex w-100 mt-2 justify-content-between">
          <span className="text-label-extra-small fw-medium text-truncate">
            Work: {workOrderData?.work_order_number}
          </span>
        </div>
      )}
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex w-100">
        <span className="fw-medium text-label-small me-8">
          Micron: <b>{data?.micron}</b>
        </span>
        <span className="fw-medium text-label-small">
          Size: <b>{data?.material_size}</b>
        </span>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <span className="fw-medium">
          Material: <b>{itemDetails?.name || itemDetails?.item}</b>
        </span>
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex w-100 justify-content-center">
        {data?.batch_number && (
          <Barcode
            displayValue={false}
            width={1}
            height={40}
            value={data?.batch_number}
          />
        )}
      </div>
      <div className="d-flex w-100 justify-content-between">
        <span className="fw-medium">
          Total: <b>{`${data?.batch_quantity || '-'}${findUnitName}`}</b>
        </span>
        <span className="text-label-small">
          Opr. <b>{operatorName}</b>
        </span>
      </div>
    </>
  )
}

export default MR
