import { Divider } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  FormInput,
  FormInputArea,
  FormSelect,
  Text,
  FormInputDate as YoloDate,
} from 'yolo-design/components'
import { ShortArrowLeft } from 'yolo-design/icons'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { useGetJobDetail, useWoList } from '@services/materialEntry'
import { Color } from 'yolo-design/constants'
import { EmptyImg } from '@constants/index'
import { stringIncludes } from '@utils/string'
import { useOperatorsList } from '@services/org'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

interface IProps {
  grnNumber: string
  form: any
}

const MaterialEntryBasic: FC<IProps> = ({ grnNumber, form }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const workOrderId = searchParams.get('work_order') || ''
  const jobId = searchParams.get('job') || ''

  const navigate = useNavigate()
  const { orgId } = useParams()

  const handleBack = () => {
    navigate(`/${orgId}/material-entry/`)
  }
  const { data: woList, isLoading } = useWoList({}, { search: '' })

  const { data } = useGetJobDetail({ enabled: !!jobId }, { id: jobId })

  const { data: operatorsList } = useOperatorsList()

  useEffect(() => {
    form.setFieldValue('job_entity', data?.id)
  }, [data])

  useEffect(() => {
    const operatorId = searchParams.get('operator')

    if (operatorId) {
      form.setFieldValue('operator', Number(operatorId))
    }

    if (workOrderId) {
      form.setFieldValue('work_order', workOrderId)
    }
  }, [])

  return (
    <div
      style={{ height: 'calc(100vh - 60px)', width: '391px' }}
      className="d-flex flex-column py-16  px-24 border-end border-1 border-grey-300"
    >
      <div
        onClick={handleBack}
        className="d-flex w-100 h-max-content align-items-center cursor-pointer"
      >
        <ShortArrowLeft
          size="large"
          weight="thick"
          onClick={() => {
            navigate(`/${orgId}/material-entry/`)
          }}
        />
        <Text
          style={{ marginLeft: '12px' }}
          value="New Material Entry "
          category="heading"
          size="small"
          weight="medium"
        />
      </div>

      <Divider
        style={{
          margin: '16px 0px 20px 0px',
          backgroundColor: 'rgba(204, 204, 204, 1)',
        }}
      />
      <Text
        category="title"
        size="medium"
        weight="semibold"
        value={`Bill No: ${grnNumber}`}
      />

      <div style={{ marginTop: '20px' }}>
        <div className="d-flex justify-content-between w-100 gap-2">
          <div style={{ width: '100%' }}>
            <YoloDate
              antdDatePickerProps={{
                format: 'MMMM D, YYYY',
                disabledDate: (el: any) => dayjs(el).isAfter(dayjs(), 'day'),
              }}
              andtFormItemProps={{
                validateTrigger: 'onChange',
                initialValue: dayjs(),
              }}
              title="Date"
              name="bill_date"
              required={true}
            />
          </div>
        </div>

        <div>
          <FormSelect
            title={'Work Order'}
            placeHolder="Select from Options"
            name={'work_order'}
            antdSelectProps={{
              filterOption: stringIncludes,
              showSearch: true,
              allowClear: true,
              onChange: (val, column: any) => {
                if (val) {
                  searchParams.set('work_order', val)
                  searchParams.set('job', column?.rec?.job)
                } else {
                  searchParams.delete('work_order')
                  searchParams.delete('job')
                }

                setSearchParams(searchParams)
              },
            }}
            required
            disabled={isLoading}
            optionsSelect={
              woList?.data?.map((el: any) => ({
                value: String(el?.id),
                label: el?.work_order_number,
                rec: el,
              })) || []
            }
          />
        </div>
        {data?.id ? (
          <>
            <FormInput
              antdInputProps={{ className: 'd-none' }}
              andtFormItemProps={{ className: 'd-none' }}
              name={'job_entity'}
            />

            <Text
              value={'Job Details'}
              category="body"
              size="medium"
              weight="medium"
            />
            <div
              style={{
                display: 'flex',
              }}
              className="border-1 border bg-white border-grey-400 p-8 rounded-4 mt-8"
            >
              <div style={{ height: '48px', width: '36px' }}>
                <img
                  style={{
                    height: '48px',
                    width: '36px',
                    objectFit: 'contain',
                  }}
                  src={data?.image || EmptyImg}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  marginLeft: '12px',
                  height: '48px',
                }}
              >
                <div>
                  <Text
                    value={data?.name}
                    category="body"
                    size="medium"
                    weight="medium"
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      value={'Job Code:'}
                      category="label"
                      size="small"
                      weight="medium"
                      color={Color?.gray_shades?.gray_700}
                    />
                    <Text
                      value={` ${data?.job_code}`}
                      category="label"
                      size="small"
                      weight="medium"
                      color={Color?.gray_shades?.gray_700}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <FormSelect
          title={'Operator'}
          placeHolder="Select Operator"
          name={'operator'}
          antdSelectProps={{
            filterOption: stringIncludes,
            showSearch: true,
            allowClear: true,
            onChange: (val) => {
              if (val) {
                searchParams.set('operator', val)
              } else {
                searchParams.delete('operator')
              }
              setSearchParams(searchParams)
            },
          }}
          required
          disabled={isLoading}
          optionsSelect={
            operatorsList?.map((el) => ({ value: el.id, label: el.name })) || []
          }
          andtFormItemProps={{
            className: 'mt-12',
          }}
        />
        <div className="mt-12">
          <FormInputArea
            title={'Notes'}
            optionalTitle="Optional"
            name="terms"
          />
        </div>
      </div>
    </div>
  )
}

export default MaterialEntryBasic
