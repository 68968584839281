import QueryClient from '@lib/QueryClient'
import AppProvider from './contexts/App.context'
import Routes from '@routes/Routes'
import '@assets/scss/style.scss'
import Sentry from '@lib/Sentry'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import weekYear from 'dayjs/plugin/weekYear'
import timezone from 'dayjs/plugin/timezone'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import localeData from 'dayjs/plugin/localeData'
import Localized from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(Localized)
function App() {
  return (
    <Sentry>
      <QueryClient>
        <AppProvider>
          <Routes />
        </AppProvider>
      </QueryClient>
    </Sentry>
  )
}

export default App
