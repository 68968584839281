import { IStockReport } from '@contracts/models/report'
import { GET } from '@lib/AxiosClient'

export const getStockReport = (p: {
  material_type?: string
  form?: string
}): Promise<IStockReport[]> => {
  return GET({
    url: `item/raw-material-stock-report/`,
    params: p,
  })
}

export const getReportFormType = (
  code: number
): Promise<
  {
    label: string
    value: string
  }[]
> =>
  GET({
    url: `item/report-form-type/${code}/`,
  })

export const getReportForm = (): Promise<
  {
    id: string
    name: string
    code: number
    maintain_inventory: boolean
  }[]
> => {
  return GET({
    url: `item/report-form/`,
  })
}
