import React, { FC, PropsWithChildren } from 'react'
import { LoginLayout } from 'yolo-design/layouts'
import { yoloInventoryLogo } from 'yolo-design/assets'

const LogoLayout: FC<
  PropsWithChildren & {
    showCancel?: boolean
    onCancel?: () => void
    contentContainerProps?: React.HTMLProps<HTMLDivElement>
  }
> = ({
  children,
  showCancel = true,
  onCancel = undefined,
  contentContainerProps = {},
}) => {
  return (
    <LoginLayout
      logo={yoloInventoryLogo}
      contentContainerProps={contentContainerProps}
      onCancel={() => !!onCancel && onCancel()}
      showCancel={showCancel && !!onCancel}
    >
      {children}
    </LoginLayout>
  )
}

export default LogoLayout
