import { ENV } from '@config/app.config'
import {
  Material,
  MaterialBatchListItem,
  MaterialIssueSubmit,
  Materials,
  RecipientName,
  WorkOrders,
} from '@contracts/models/materials-issue'
import { PaginatedResponse } from '@contracts/models/shared'
import { GET, POST } from '@lib/AxiosClient'

export const getMaterialList = (
  signal: AbortSignal | undefined,
  params: {
    search?: string
    page: string
    page_size: number
  }
): Promise<PaginatedResponse<Material>> =>
  GET({
    url: `${ENV.API_BASE_URL}item/raw-material/`,
    params,
    signal,
  })

export const getWorkOrderList = (id?: string): Promise<WorkOrders[]> =>
  GET({ url: `${ENV.API_BASE_URL}process/work-order/${id}/` })

export const getMaterialDetailsbyId = (id: number): Promise<Materials> =>
  GET({ url: `${ENV.API_BASE_URL}item/raw-material/${id}/` })

export const getMaterialBatchList = (
  signal: AbortSignal | undefined,
  params: {
    search?: string
    page: string
    page_size: number
  },
  id: string
): Promise<PaginatedResponse<MaterialBatchListItem>> =>
  GET({
    url: `${ENV.API_BASE_URL}item/raw-material-batch/${id}/`,
    params,
    signal,
  })

export const getRecipientNameList = (name: string): Promise<RecipientName[]> =>
  GET({ url: `${ENV.API_BASE_URL}organization/recipient/?search=${name}` })

export const postIssuedMaterials = (data: MaterialIssueSubmit) =>
  POST({ url: `${ENV.API_BASE_URL}item/stock-issue/`, data })
export const ReportMaterialBatchList = (params: {
  product?: string
  from?: string
  to?: string
}): Promise<PaginatedResponse<any>> =>
  GET({
    url: `item/issue-report/`,
    params,
  })
export const ReportMaterialReturnList = (params: {
  product?: string
  from?: string
  to?: string
}): Promise<PaginatedResponse<any>> =>
  GET({
    url: `item/return-report/`,
    params,
  })
