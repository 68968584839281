import { DefaultOptionType } from 'antd/es/select'

export const stringIncludes: any = (input: string, option: DefaultOptionType) =>
  (String(option?.label) ?? '').toLowerCase().includes(input.toLowerCase())

export const getFormattedDate = () => {
  const cur = new Date()

  return `${cur.getUTCFullYear()}-${cur.getMonth() + 1}-${cur.getDate()}`
}
