import { useQuery } from '@tanstack/react-query'
import * as API from './api'
import { queries } from '@constants/queryKeys'
import { UseQueryDataType, UseQueryType } from '@contracts/query'
import { IStockReport } from '@contracts/models/report'

export const useStockReport: UseQueryDataType<
  IStockReport[],
  { material_type?: string; form?: string }
> = (options, p) =>
  useQuery({
    queryFn: () => API.getStockReport(p),
    queryKey: queries.report.stock(p).queryKey,

    ...options,
  })

export const useReportFormType: UseQueryDataType<
  { value: string; label: string }[],
  number
> = (options, code) =>
  useQuery({
    queryFn: () => API.getReportFormType(code),
    queryKey: queries.report.formType(code).queryKey,
    enabled: !!code,
    ...options,
  })

export const useReportForm: UseQueryType<
  {
    id: string
    name: string
    code: number
    maintain_inventory: boolean
  }[]
> = (options) =>
  useQuery({
    queryFn: API.getReportForm,
    queryKey: queries.report.form.queryKey,
    ...options,
  })
