import { useMutation, useQuery } from '@tanstack/react-query'
import {
  UseInfiniteQueryDataType,
  UseMutationType,
  UseQueryDataType,
} from '@contracts/query'
import * as API from './api'
import { queries } from '@constants/queryKeys'
import {
  Material,
  MaterialBatchListItem,
  MaterialIssueSubmit,
  Materials,
  RecipientName,
  WorkOrders,
} from '@contracts/models/materials-issue'
import useInfiniteQueryRef from '@hooks/useInfiniteQueryRef'

export const useGetMaterialDetailsById: UseQueryDataType<
  Materials,
  { id: number }
> = (options, { id }) =>
  useQuery({
    queryKey: queries.materials.getMaterialDetailsId(id).queryKey,
    queryFn: () => API.getMaterialDetailsbyId(id),
    ...options,
  })

export const useGetMaterialsArray: UseInfiniteQueryDataType<
  Material,
  { search?: string; page_size?: number; orgId: string }
> = (options, { search, page_size = 10, orgId }) =>
  useInfiniteQueryRef<Material>({
    queryKey: queries.materials.listWithMaterials(search, page_size, orgId)
      .queryKey,
    queryFn: ({ signal, pageParam }) => {
      return API.getMaterialList(signal, {
        search,
        page: pageParam,
        page_size: page_size,
      })
    },
    ...options,
  })

export const useGetWorkOrderList: UseQueryDataType<
  WorkOrders[],
  { id?: string }
> = (options, { id }) =>
  useQuery({
    queryKey: queries.materials.getWorkOrderListId(id).queryKey,
    queryFn: () => API.getWorkOrderList(id),
    ...options,
  })

export const useGetMaterialBatch: UseInfiniteQueryDataType<
  MaterialBatchListItem,
  { id: string; search?: string; page_size: number; orgId: string }
> = (options, { id, search, orgId, page_size = 10 }) =>
  useInfiniteQueryRef({
    queryKey: queries.materials.listWithBatchMaterials(
      search,
      page_size,
      orgId,
      id
    ).queryKey,
    queryFn: ({ signal, pageParam }) =>
      API.getMaterialBatchList(
        signal,
        {
          search,
          page: pageParam,
          page_size,
        },
        id
      ),
    ...options,
  })

export const useGetRecipientName: UseQueryDataType<
  RecipientName[],
  { name: string }
> = (options, { name }) =>
  useQuery({
    queryKey: queries.materials.listWithRecipientName(name).queryKey,
    queryFn: () => API.getRecipientNameList(name),
    ...options,
  })

export const usePostMaterialIssue: UseMutationType<
  { message: string },
  MaterialIssueSubmit
> = (options) =>
  useMutation({
    mutationFn: API.postIssuedMaterials,
    ...options,
  })

export const useGetReportIssueMaterial: UseQueryDataType<
  any,
  {
    orgId: string
    from?: string
    to?: string
    product?: string
  }
> = (options, { product, from, to, orgId }) =>
  useQuery({
    queryKey: queries.materials.getReportIssueMaterial({
      orgId,
      product,
      from,
      to,
    }).queryKey,
    queryFn: () => {
      return API.ReportMaterialBatchList({
        product,
        from,
        to,
      })
    },
    ...options,
  })

export const useGetReportReturnMaterial: UseQueryDataType<
  any,
  {
    orgId: string
    from?: string
    to?: string
    product?: string
  }
> = (options, { product, from, to, orgId }) =>
  useQuery({
    queryKey: queries.materials.getReportReturnMaterial({
      orgId,
      product,
      from,
      to,
    }).queryKey,
    queryFn: () => {
      return API.ReportMaterialReturnList({
        product,
        from,
        to,
      })
    },
    ...options,
  })
