import { queries } from '@constants/queryKeys'
import { UseMutationType, UseQueryDataType } from '@contracts/query'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as API from './api'
import { IVendorList } from '@contracts/models/vendor'

export const useVendorListInfo: UseQueryDataType<
  IVendorList,
  { search?: string; page_size?: number }
> = (options, { search, page_size }) =>
  useQuery({
    queryKey: queries.vendor.list(search, page_size).queryKey,
    queryFn: ({ signal, pageParam }) => {
      return API.getVendors(signal, {
        search,
        page: pageParam,
        page_size,
      })
    },
    ...options,
  })

export const useGetVendor: UseQueryDataType<any, { id: number }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.vendor.get(id).queryKey,
    queryFn: () => API.getVendorById(id),
    ...options,
  })

export const useUpdateVendor: UseMutationType<
  any,
  Partial<any> & { id: number }
> = (options) => useMutation(API.putVendorAddress, options)
