import InwardView from '@components/grn/InwardView'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Drawer, Table } from 'yolo-design/components'

const MaterialStock = ({
  list,
  isLoading,
  lastItemRef,
  GrnRefetch,
}: {
  list: any
  isLoading: boolean
  lastItemRef: any
  GrnRefetch: any
}) => {
  const [viewGrn, sewtViewGrn] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const defaultColumns: any = [
    {
      title: 'Bill Date',
      dataIndex: 'bill_date',
      width: '10%',
      render: (text: string) => (
        <>{text ? dayjs(text).format('DD-MMM-YYYY') : '-'}</>
      ),
    },
    {
      title: 'ME number',
      dataIndex: 'bill_number',
      width: '10%',
      render: (rec: string, _: any, key: number) => (
        <>
          <div
            ref={list?.length || []?.length - 1 === key ? lastItemRef : null}
            className="Product_Name_Class d-flex justify-content-between align-items-center"
          >
            <div className="d-flex align-items-center">
              <div>{rec}</div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Work Orders',
      dataIndex: 'work_orders',
      render: (text: any) => {
        return (
          <>
            {' '}
            {text ? (
              <>
                {text?.map((po: any, index: any) => (
                  <Tag
                    key={index}
                    style={{
                      marginTop: '4px',
                      marginBottom: '4px',
                      lineHeight: '26px',
                      fontSize: '14px',
                    }}
                  >
                    {po}
                  </Tag>
                ))}
              </>
            ) : (
              <>-</>
            )}{' '}
          </>
        )
      },
    },
    {
      title: 'Jobs',
      dataIndex: 'jobs',
      render: (text: any) => {
        return (
          <>
            {' '}
            {text ? (
              <>
                {text?.map((po: any, index: any) => (
                  <Tag
                    key={index}
                    style={{
                      marginTop: '4px',
                      marginBottom: '4px',
                      lineHeight: '26px',
                      fontSize: '14px',
                    }}
                  >
                    {po}
                  </Tag>
                ))}
              </>
            ) : (
              <>-</>
            )}{' '}
          </>
        )
      },
    },

    {
      title: 'No Of Items',
      dataIndex: 'no_of_items',
      width: '10%',
      render: (text: string) => <>{text ? text : '-'}</>,
    },
    {
      title: 'Operator',
      dataIndex: 'operator_name',
      render: (text: string) => <>{text ? text : '-'}</>,
    },

    {
      title: <span className="">STATUS</span>,
      dataIndex: 'bill_status__name',
      render: (text: any, rec: any) => {
        return (
          <>
            <div
              className="spacer"
              style={{
                width: 'max-content',
                backgroundColor: rec?.color_code,
                borderRadius: '4px',
                padding: '2px 4px',
              }}
            >
              {text || '-'}
            </div>
          </>
        )
      },
      align: 'left',
      width: '10%',
    },
  ]

  return (
    <>
      <div className="mt-16">
        <Table
          columns={defaultColumns}
          dataSource={list || []}
          rowKey={'id'}
          loading={isLoading}
          onRow={(data) => ({
            onClick: () => {
              searchParams.set('billId', data?.id)
              setSearchParams(searchParams)
              sewtViewGrn(true)
            },
            style: { cursor: 'pointer' },
          })}
        />
      </div>
      <Drawer
        width={800}
        placement="right"
        closable={false}
        open={viewGrn}
        customHeader={true}
        destroyOnClose={true}
      >
        <InwardView setOpen={sewtViewGrn} grnRefetch={GrnRefetch} />
      </Drawer>
    </>
  )
}
export default MaterialStock
