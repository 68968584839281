import { Divider } from 'antd'
import React, { FC } from 'react'
import Barcode from 'react-barcode'

type Iprops = {
  data?: any
  billDate?: any
  isReturn?: boolean
  itemDetails?: any
  tarQty: number
  findUnitName: any
}

const GRN: FC<Iprops> = ({
  data,
  billDate,
  isReturn,
  itemDetails,
  tarQty,
  findUnitName,
}) => {
  return (
    <>
      <div className="d-flex w-100 text-label-extra-small justify-content-between">
        <span className="text-label-extra-small text-truncate">
          B.No: <b>{data?.batch_number || '-'}</b>
        </span>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <span className="text-label-extra-small w-100 text-truncate">
          <b>Date:{billDate?.format('DD-MM-YY/hh:MM A') || '-'}</b>
        </span>
        {!!isReturn && (
          <span style={{ lineHeight: 0 }} className="text-label-extra-small">
            {'Returned'}
          </span>
        )}
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex justify-content-between">
        <span className="mt-2 w-100 text-label-extra-small text-truncate fw-semibold">
          Material:{' '}
          <b style={{ lineHeight: 1 }} className="text-label-medium">
            {itemDetails?.name || itemDetails?.item}
          </b>{' '}
        </span>
      </div>
      <div className="d-flex">
        {data?.micron ? (
          <>
            <span className="text-label-extra-small fw-semibold">
              Micron:
              <b style={{ lineHeight: 1 }} className="text-label-medium ">
                {data?.micron || '-'}
              </b>
              &nbsp;&nbsp;
            </span>
          </>
        ) : (
          <></>
        )}
        {data?.material_size ? (
          <>
            <span className="text-label-extra-small fw-semibold">
              Size:
              <b style={{ lineHeight: 1 }} className="text-label-medium ">
                {data?.material_size || '-'}
              </b>
              &nbsp;&nbsp;
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="d-flex ">
        {tarQty > 0 ? (
          <>
            <span className="text-label-extra-small fw-semibold">
              Tar:
              <b style={{ lineHeight: 1 }} className="text-label-medium ">
                {`${tarQty > 0 ? tarQty : 0} ${findUnitName}` || '-'}
              </b>
              &nbsp;&nbsp;
            </span>
          </>
        ) : (
          <></>
        )}
        {data?.batch_quantity ? (
          <>
            <span className="text-label-extra-small fw-semibold">
              Total:
              <b style={{ lineHeight: 1 }} className="text-label-medium">{`${
                data?.batch_quantity || '-'
              }${findUnitName}`}</b>
              &nbsp;&nbsp;
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex w-100 justify-content-center">
        {data?.batch_number && (
          <Barcode
            displayValue={false}
            width={1}
            height={30}
            value={data?.batch_number}
          />
        )}
      </div>
    </>
  )
}

export default GRN
