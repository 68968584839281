import { ENV } from '@config/app.config'
import { IGrn } from '@contracts/models/grn'
import { PaginatedResponse } from '@contracts/models/shared'
import { GET, POST } from '@lib/AxiosClient'

export const getMeBillNumInfo = (): Promise<any> =>
  GET({
    url: 'item/get-material-entry-number/',
  })

export const getWoList = (search: string, ids: any): Promise<any> => {
  const idsParms = ids ? ids : ''
  return GET({
    url: `process/v2/work-order/?search=${search}&ids=${idsParms}&is_completed=false`,
  })
}

export const getMeList = (
  signal: AbortSignal | undefined,
  params: {
    search?: string
    page: number
    page_size: number
  }
): Promise<PaginatedResponse<IGrn>> =>
  GET({ url: 'item/material-entry/', params, signal })

export const materialEntryCreate = (data: Partial<any>): Promise<any> =>
  POST({
    url: `item/material-entry/`,
    data: data?.grnData,
  })
export const getmaterial = (id: number): Promise<any> => {
  return GET({
    url: `item/material-entry/${id}/`,
  })
}
export const getGrnBill = (id: number): Promise<any> => {
  return GET({
    url: `item/grn-report/${id}/`,
  })
}
export const getJobbByWorkOrder = (id: string): Promise<any> => {
  return GET({
    url: `${ENV.INVOICE_API_BASE_URL}process/job-retrieve/${id}/`,
  })
}
