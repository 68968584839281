import { ENV } from '@config/app.config'

import { getCookie, removeCookie } from './cookie'

import { TOKEN_KEY, ORG_KEY } from '@constants/index'
import {
  removeAuthHeader,
  removeOrgHeader,
  setAuthHeader,
  setOrgHeader,
} from '@lib/AxiosClient'

export const getPasscode = () => getCookie(TOKEN_KEY)
export const getUsername = () => getCookie('userName')
export const getOrgId = () => getCookie(ORG_KEY)

const oneYearFromNow = () =>
  new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()
export const setPasscode = (value: string) => {
  const cookie = `${TOKEN_KEY}=${value};domain=${
    ENV.WEB_DOMAIN
  }; path=/; hostOnly=false; secure=true; session=true; Expires=${oneYearFromNow()};`

  document.cookie = cookie

  setAuthHeader(value)
}

export const removePasscode = () => {
  removeCookie(TOKEN_KEY)
  removeAuthHeader()
}

export const setOrgId = (value: string) => {
  const cookie = `${ORG_KEY}=${value};;domain=${ENV.WEB_DOMAIN}; path=/; hostOnly=true; secure=true; session=true;`
  document.cookie = cookie
  setOrgHeader(value)
}

export const removeOrgId = () => {
  removeCookie(ORG_KEY)
  removeOrgHeader()
}

export const setPort = (value: string) => {
  const port = value
  localStorage.setItem('port', port)
}

export const getPort = () => {
  return localStorage.getItem('port')
}
