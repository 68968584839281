import React from 'react'
import { Text } from 'yolo-design/components'
import './GrnBill.css'
import { Col, Row } from 'antd'

interface Props {
  data?: any
  elRef?: any
  type?: number
}
const GrnBill: React.FC<Props> = ({ data, elRef }) => {
  const templateData = data

  const getAddress = (addressDetail: any) => {
    const address = addressDetail || {}
    const address1 = address.address1 || ''
    const address2 = address.address2 ? `,${address.address2}` : ''
    const city = address.city || ''
    const state = address.state_name ? `${address.state_name}` : ''
    const pincode = address.pincode ? `-${address.pincode}` : ''
    const country = address.country_name ? `,${address.country_name}` : ''
    const Address = {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      pincode: pincode,
      country: country,
    }
    return Address
  }

  const vendorBillingAddress = templateData?.vendor_address?.find(
    (f: any) => f.address_type === 1
  )
  const orgBillingAddress = templateData?.organization_address?.find(
    (f: any) => f.address_type === 1
  )
  const orgShippingAddress =
    templateData?.organization_address?.find(
      (f: any) => f.address_type === 2
    ) || orgBillingAddress

  const vendorBillingAddressFirst = `${
    getAddress(vendorBillingAddress)?.address1
  } ${getAddress(vendorBillingAddress)?.address2}`
  const vendorBillingAddressSecond = `${
    getAddress(vendorBillingAddress)?.city
  }, ${getAddress(vendorBillingAddress)?.state}${
    getAddress(vendorBillingAddress)?.pincode
  } ${getAddress(vendorBillingAddress)?.country}`

  const orgBillingAddressFirst = `${getAddress(orgBillingAddress)?.address1}${
    getAddress(orgBillingAddress)?.address2
  }`
  const orgBillingAddressSecond = `${getAddress(orgBillingAddress)?.city},${
    getAddress(orgBillingAddress)?.state
  }${getAddress(orgBillingAddress)?.pincode}${
    getAddress(orgBillingAddress)?.country
  }`
  const orgShippingAddressFirst = `${getAddress(orgShippingAddress)?.address1}${
    getAddress(orgShippingAddress)?.address2
  }`
  const orgShippingAddressSecond = `${getAddress(orgShippingAddress)?.city},${
    getAddress(orgShippingAddress)?.state
  }${getAddress(orgShippingAddress)?.pincode}${
    getAddress(orgShippingAddress)?.country
  }`
  const itemTotals: any = {}

  data?.item_details?.forEach((item: any) => {
    const itemName = item.item
    if (!itemTotals[itemName]) {
      itemTotals[itemName] = {
        tar_quantity: item.tar_quantity,
        gross_quantity: item.gross_quantity,
        net_quantity: item.net_quantity,
      }
    } else {
      itemTotals[itemName].tar_quantity += item.tar_quantity
      itemTotals[itemName].gross_quantity += item.gross_quantity
      itemTotals[itemName].net_quantity += item.net_quantity
    }
  })
  return (
    <>
      <div
        ref={elRef}
        style={{
          backgroundColor: 'white',
          padding: '4px',
          width: '100%',
          height: '100vh',
        }}
      >
        <table className="bg-white" style={{ width: '100%' }}>
          <thead className="page-header">
            <tr>
              <td>
                <div
                  style={{ width: '100%' }}
                  className="d-flex justify-content-between"
                >
                  <div className="d-flex position-relative w-100">
                    <div
                      style={{
                        transform: 'translateY(-50%)',
                      }}
                      className="d-flex me-12 align-items-center position-absolute top-50 start-0"
                    >
                      {!!templateData?.logo && (
                        <img
                          src={templateData?.logo}
                          alt={templateData?.branch}
                          width={90}
                          height={90}
                          className="object-fit-contain"
                        />
                      )}
                    </div>
                    <div className="d-flex w-100 justify-content-center">
                      <div
                        className="d-flex  flex-column align-items-center"
                        style={{ marginTop: '8px' }}
                      >
                        <Text
                          category="title"
                          size="large"
                          style={{ fontSize: '20px' }}
                          weight="medium"
                          value={templateData?.branch}
                          className="text-break"
                        />
                        <Text
                          category="label"
                          size="small"
                          weight="regular"
                          value={orgBillingAddressFirst}
                          className="mt-8 text-break"
                        />
                        <Text
                          category="label"
                          size="small"
                          weight="regular"
                          value={orgBillingAddressSecond}
                          className="mt-4 text-break"
                        />
                        <div className="d-flex gap-2">
                          {templateData?.org_phone ? (
                            <>
                              <Text
                                category="label"
                                size="small"
                                weight="regular"
                                value={`Ph: ${templateData?.org_phone} `}
                                className="mt-4"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {templateData?.website ? (
                            <>
                              <Text
                                category="label"
                                size="small"
                                weight="regular"
                                value={`
                           Website:
                ${templateData?.website}`}
                                className="mt-4"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="d-flex gap-2">
                          {templateData?.org_gst ? (
                            <>
                              <Text
                                category="label"
                                size="small"
                                weight="bold"
                                value={`
                           GST:
                ${templateData?.org_gst}`}
                                className="mt-4"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {templateData?.org_pan_number ? (
                            <>
                              <Text
                                category="label"
                                size="small"
                                weight="bold"
                                value={`
                           PAN:
                ${templateData?.org_pan_number}`}
                                className="mt-4"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <Text
                          category="title"
                          style={{ fontSize: '20px', marginTop: '12px' }}
                          weight="medium"
                          size="large"
                          value={'GOODS RECEIPT NOTES'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          {/* Bills Details */}
          <div
            style={{ width: '100%' }}
            className="mt-12 d-flex  border border-opacity-50 border-end-0 border-start-0 border-grey-400 justify-content-between"
          >
            <div
              style={{ width: '50%' }}
              className="border border-top-0 border-start-0 border-opacity-50  border-grey-400 border-bottom-0 "
            >
              <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-top-0 border-grey-400 justify-content-between py-4">
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={'GRN NO'}
                />
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={templateData?.bill_number || '-'}
                  className="pe-4"
                />
              </div>
              <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-bottom-0 border-top-0 border-grey-400 justify-content-between py-4">
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={'INVOICE NO'}
                />
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={templateData?.reference_number || '-'}
                  className="pe-4"
                />
              </div>
              <div className=" d-flex border border-start-0 border-bottom-0 border-opacity-50 border-end-0 border-grey-400 justify-content-between align-items-center py-4">
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={'NO OF PO'}
                  className=""
                />
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={templateData?.purchase_orders?.length || '-'}
                  className=" pe-4"
                />
              </div>
            </div>

            <div
              style={{ width: '50%' }}
              className="border border-top-0 border-start-0 border-end-0 border-grey-400 border-bottom-0 "
            >
              <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-top-0 border-grey-400 justify-content-between py-4">
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={'GRN DATE'}
                  className="ps-4 "
                />
                <div className="d-flex justify-content-end w-50">
                  <Text
                    category="label"
                    size="small"
                    weight="regular"
                    value={templateData?.bill_date || '-'}
                    className="text-break"
                  />
                </div>
              </div>
              <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-bottom-0 border-top-0 border-grey-400 justify-content-between py-4">
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={'INVOICE DATE'}
                  className="ps-4"
                />
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={templateData?.reference_date || '-'}
                  className=""
                />
              </div>
              <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-bottom-0  border-grey-400 justify-content-between py-4">
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={'DELIVERY DATE'}
                  className="ps-4"
                />
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={
                    templateData?.immediate
                      ? 'Immediate'
                      : `${templateData?.delivery_date || '-'}
                  `
                  }
                  className=""
                />
              </div>
            </div>
          </div>
          {templateData?.purchase_orders?.length ? (
            <>
              <div className="mt-0 w-100 py-4   d-flex  border border-top-0 border-opacity-50 border-end-0 border-start-0 border-grey-400 gap-4 ">
                <div className="w-max-content">
                  <Text
                    category="label"
                    size="small"
                    weight="regular"
                    value={'PURCHASE ORDERS:'}
                    className="w-max-content"
                  />
                </div>
                <Row gutter={[0, 2]}>
                  {templateData?.purchase_orders?.map((po: any, i: any) => {
                    return (
                      <>
                        <Col>
                          <Text
                            category="label"
                            size="small"
                            weight="regular"
                            value={
                              `${po}${
                                templateData?.purchase_orders?.length - 1 === i
                                  ? ''
                                  : ','
                              }` || '-'
                            }
                            className="w-100"
                          />
                        </Col>
                      </>
                    )
                  })}
                </Row>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* Address Details */}
          <div className="mt-12 d-flex gap-2 w-100 justify-content-between">
            <div className="ps-4 py-4 rounded-2 h-100 w-50 bg-grey-100">
              <Text
                category="label"
                size="small"
                weight="regular"
                value={'VENDOR DETAILS'}
              />

              <Text
                category="label"
                size="small"
                weight="bold"
                className="mt-8 text-break"
                value={templateData?.vendor}
              />
              <Text
                category="label"
                size="small"
                weight="regular"
                value={vendorBillingAddressFirst}
                className="mt-8 text-break"
              />
              <Text
                category="label"
                size="small"
                weight="regular"
                value={vendorBillingAddressSecond}
                className="mt-4 text-break"
              />
              <div className="d-flex gap-8 align-items-center">
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={`GST: ${templateData?.gst || '-'}`}
                  className="mt-8 border-end pe-4"
                />
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={`PAN: ${templateData?.pan_number || '-'}`}
                  className="mt-8"
                />
              </div>
              <div className="d-flex gap-2 ">
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={`Ph No: ${templateData?.vendor_phone || '-'}`}
                  className="mt-8 border-end pe-4"
                />
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={`Email: ${templateData?.vendor_email || '-'}`}
                  className="mt-8"
                />
              </div>
            </div>
            <div className=" d-flex gap-2 w-50 h-100 flex-column">
              <div className="d-flex flex-column h-100 rounded-2 ps-4 py-4 bg-grey-100">
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={'DELIVERED TO'}
                />
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={orgShippingAddressFirst}
                  className="mt-8 text-break"
                />
                <Text
                  category="label"
                  size="small"
                  weight="regular"
                  value={orgShippingAddressSecond}
                  className="mt-4 text-break"
                />
              </div>
            </div>
          </div>
          <tbody>
            <tr>
              <td>
                <div style={{ width: '100%' }}>
                  <div>
                    {' '}
                    <div className="mt-16 ">
                      <table className="w-100 temp-table">
                        <tr className="bg-grey-100">
                          <th
                            style={{
                              fontSize: '12px',
                              textAlign: 'left',
                              fontWeight: '500',
                            }}
                            className=" "
                          >
                            #
                          </th>
                          <th
                            style={{
                              fontSize: '12px',

                              textAlign: 'left',
                              fontWeight: '500',
                            }}
                            className=" py-4"
                          >
                            Particulars
                          </th>

                          <th
                            style={{
                              fontSize: '12px',

                              textAlign: 'center',
                              fontWeight: '500',
                            }}
                            className=" py-4"
                          >
                            Sku
                          </th>
                          <th
                            style={{
                              fontSize: '12px',

                              textAlign: 'center',
                              fontWeight: '500',
                            }}
                            className=" py-4"
                          >
                            Notes
                          </th>
                          <th
                            style={{
                              fontSize: '12px',

                              textAlign: 'center',
                              fontWeight: '500',
                            }}
                            className=" py-4"
                          >
                            Gross Qty
                          </th>

                          <th
                            style={{
                              fontSize: '12px',

                              textAlign: 'center',
                              fontWeight: '500',
                            }}
                            className=" py-4"
                          >
                            Net Qty
                          </th>

                          <th
                            style={{
                              fontSize: '12px',

                              textAlign: 'center',
                              fontWeight: '500',
                            }}
                            className=" py-4"
                          >
                            Tar Qty
                          </th>

                          <th
                            style={{
                              fontSize: '12px',

                              textAlign: 'center',
                              fontWeight: '500',
                            }}
                            className=" py-4"
                          >
                            UOM
                          </th>
                        </tr>
                        {data?.item_details?.map((el: any, i: number) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  fontSize: '12px',
                                  textAlign: 'left',
                                  padding: 'ps-2',
                                }}
                                className="fw-medium"
                              >
                                {i + 1}
                              </td>
                              <td
                                style={{
                                  fontSize: '12px',
                                  textAlign: 'left',
                                }}
                                className="fw-normal"
                              >
                                {el?.item_name}
                              </td>

                              <td
                                style={{
                                  fontSize: '12px',
                                  textAlign: 'center',
                                }}
                                className="fw-normal"
                              >
                                {el?.item_sku_code || ''}
                              </td>
                              <td
                                style={{
                                  fontSize: '12px',
                                  textAlign: 'center',
                                }}
                                className="fw-normal"
                              >
                                {el?.notes || '-'}
                              </td>
                              <td
                                style={{
                                  fontSize: '12px',
                                  textAlign: 'center',
                                }}
                                className="fw-normal"
                              >
                                {`${el?.gross_quantity || 0}`}
                              </td>
                              <td
                                style={{
                                  fontSize: '12px',
                                  textAlign: 'center',
                                }}
                                className="fw-normal"
                              >
                                {`${el?.net_quantity || 0}`}
                              </td>
                              <td
                                style={{
                                  fontSize: '12px',
                                  textAlign: 'center',
                                }}
                                className="fw-normal"
                              >
                                {`${el?.tar_quantity || 0}`}
                              </td>

                              <td
                                style={{
                                  fontSize: '12px',
                                  textAlign: 'center',
                                }}
                                className="fw-normal"
                              >
                                {`${el?.unit_name}`}
                              </td>
                            </tr>
                          )
                        })}
                        {Object.keys(itemTotals).map((itemName, index) => (
                          <tr key={`total-${index}`}>
                            <td>
                              <th className="text-label-large">Total</th>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th className="text-label-large">
                              {Number(
                                itemTotals[itemName].gross_quantity
                              ).toFixed(2)}
                            </th>

                            <th className="text-label-large">
                              {Number(
                                itemTotals[itemName].net_quantity
                              ).toFixed(2)}
                            </th>
                            <th className="text-label-large">
                              {Number(
                                itemTotals[itemName].tar_quantity
                              ).toFixed(2)}
                            </th>

                            <td></td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <div
            style={{ width: '50%' }}
            className="border border-top-0 border-start-0 border-end-0 border-grey-100 border-bottom-0 p-8  "
          >
            <div className="d-flex flex-column w-100  justify-content-between">
              <Text category="label" size="small" value={'NOTES'} />
              <p className="text-label-small text-break py-4 fw-normal ">
                {templateData?.notes || '-'}
              </p>
            </div>
          </div>
          <tfoot>
            {/* <tr>
              <td>
                <div className=" d-flex justify-content-between position-fixed border-top pt-8 bottom-0 w-100">
                  <div>
                    <img src={yoloInventoryLogo} height={18} width={70} />
                  </div>
                  <div className="pe-4">
                    <Text
                      category="label"
                      size="extra_small"
                      weight="medium"
                      value={'1'}
                      color={'#666666'}
                      className="pe-8"
                    />
                  </div>
                </div>
              </td>
            </tr> */}
          </tfoot>
        </table>
      </div>
    </>
  )
}
export default GrnBill
