// import { IProduct } from '@contracts/models/beatRoute'

import { preventE, preventMinus } from 'yolo-design/utils'

export const formIntialInputRestriction = (e: any) => {
  if (e.target.value.charAt(0) === '0') {
    e.target.value = e.target.value.slice(1)
  }

  restrictInitialSpace(e)
}

export const restrictInitialSpace = (e: any) => {
  const charCode = e.key
  const inputValue = e.target.value
  const currentValueLength = inputValue?.length
  if (
    !!charCode &&
    !currentValueLength &&
    (e.keyCode === 32 || e.which === 32)
  ) {
    e.preventDefault()
  }
}

export const restrictToNumbersAndDots = (e: any) => {
  const pattern = /^(\d+|\d*\.\d{1,2})$/
  const val = Number(e.target.value)
  if (!pattern.test(String(val))) {
    const value = e.target.value.slice(0, -1)
    e.target.value = value
    e.preventDefault()
  }
  formIntialInputRestriction(e)
}

interface Item {
  unit_id: number
  unit_name: string
  quantity: number
  base_quantity: number
  is_select: boolean
}
// export const stockValidatiionFunction = (
//   list: IProduct[],
//   item_id: number,
//   currentUnit: number
// ) => {
//   const getSelectedItemList = list?.filter((el: any) => el?.item_id === item_id)

//   const getFinalJson = getSelectedItemList?.map((el) => {
//     const findUnit = el?.item_units?.find(
//       (units: any) => units?.unit_id === el?.selectedUnit
//     )
//     const unitObj = {
//       unit_id: findUnit?.unit_id,
//       unit_name: findUnit?.unit,
//       quantity: el?.productQuantity,
//       base_quantity: findUnit?.base_quantity,
//       is_select:
//         Number(el?.selectedUnit) === Number(currentUnit) ? true : false,
//     }
//     return unitObj
//   })
//   const array = [...getFinalJson]
//   const groupedObject: { [key: string]: Item } = {}

//   array.forEach((obj: any) => {
//     const key = `${obj.unit_id}-${obj.unit_name}`
//     if (!groupedObject[key]) {
//       groupedObject[key] = { ...obj }
//     } else {
//       groupedObject[key].quantity += obj.quantity
//     }
//   })

//   const resultArray: Item[] = Object.values(groupedObject)
//   console.log(resultArray)
// }

interface IProduct {
  item_id: number
  item_units: any[] // Replace with the actual type
  selectedUnit: number
  productQuantity: number
}

export const stockValidationFunction = (
  list: IProduct[],
  item_id: number,
  currentUnit: number
) => {
  // Filter the list to get selected items
  const selectedItems = list.filter(
    (product: IProduct) => product.item_id === item_id
  )

  // Transform selected items into the desired format
  const formattedItems = [...selectedItems].map((product: IProduct) => {
    const selectedUnit = product.item_units.find(
      (unit: any) => unit.unit_id === product.selectedUnit
    )

    return {
      unit_id: selectedUnit?.unit_id,
      unit_name: selectedUnit?.unit,
      quantity: product.productQuantity,
      base_quantity: selectedUnit?.base_quantity,
      is_select: selectedUnit?.unit_id === currentUnit,
    }
  })

  // Group formatted items by unit_id and unit_name
  const groupedItems: { [key: string]: Item } = {}
  formattedItems.forEach((item: Item) => {
    const key = `${item.unit_id}-${item.unit_name}`
    if (!groupedItems[key]) {
      groupedItems[key] = { ...item }
    } else {
      groupedItems[key].quantity += item.quantity
    }
  })

  // Get the result as an array
  const resultArray: Item[] = Object.values(groupedItems)
  const getTotalSelectQuantity = resultArray
    ?.map((el) => el?.quantity * el?.base_quantity)
    ?.reduce((acc, cur) => acc + cur, 0)
  const finalArray = {
    item_id: item_id,
    unit: resultArray,
    quantity: getTotalSelectQuantity,
  }

  return finalArray
}

export const formDecimalInputRestriction = (e: any) => {
  const maxLength = e.target.maxLength
  if (maxLength && maxLength > 0 && e.target.value.length > maxLength) {
    e.target.value = e.target.value.slice(0, maxLength)
  }
  const pattern = /^(\d+|\d*\.\d{1,3})$/
  if (!pattern.test(Number(e.target.value))) {
    const value = e.target.value.slice(0, -1)
    e.target.value = value
    e.preventDefault()
  }

  e.target.value = e.target.value.replace(
    /[^0-9.]|(\.(?=.*\.))|(\.[0-9]*\.[0-9]*)|^(\.[0-9]{1,3})|(\.\d{4,})/gi,
    ''
  )
  preventE(e)
  preventMinus(e)
  restrictInitialSpace(e)
}
