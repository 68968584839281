import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { Menu } from 'yolo-design/components'
import { IdCard } from 'yolo-design/icons'
export default function ReportTab() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const menus: any[] = [
    {
      title: 'REPORT',
      items: [
        {
          label: <NavLink to={'material-issue'}>Material Issue</NavLink>,
          key: 'material-issue',
          icon: IdCard,
          link: 'material-issue',
        },
        {
          label: <NavLink to={'material-return'}>Material Return</NavLink>,
          key: 'material-return',
          icon: IdCard,
          link: 'material-return',
        },
      ],
    },
    {
      title: 'INVENTORY',
      items: [
        {
          label: <NavLink to={'stock'}>Stock</NavLink>,
          key: 'stock',
          icon: IdCard,
          link: 'stock',
        },
      ],
    },
  ]

  const selectMenu = pathname?.split('/')[3] || menus[0]?.items[0]?.key

  return (
    <div className="d-flex w-100">
      <div
        style={{ flex: `0 0 340px`, maxWidth: '340px' }}
        className="border-end  border-grey-200 d-flex flex-column overflow-hidden"
      >
        {menus?.map((ell) => (
          <Menu
            key={ell.title}
            onClick={(el: any) => navigate(el)}
            title={ell.title}
            selectedKey={selectMenu}
            items={ell.items}
          />
        ))}
      </div>
      <div
        style={{ flex: 1 }}
        className="d-flex justify-content-center overflow-scroll"
      >
        <Outlet />
      </div>
    </div>
  )
}
