import { Divider } from 'antd'
import { FC, RefObject } from 'react'
import cn from 'classnames'
import GRN from './GRN'
import MR from './MR'
import { IJob, IWorkOrder } from '@contracts/models/grn'

const BatchCardPrint: FC<{
  elRef?: RefObject<any>
  data?: any
  billDate?: any
  itemDetails: any
  isReturn?: boolean
  grnNo?: string
  unitName?: string
  isMR?: boolean
  jobData?: IJob
  workOrderData?: IWorkOrder
  operatorName?: string
}> = ({
  elRef,
  data,
  billDate,
  itemDetails,
  unitName,
  isReturn,
  isMR,
  jobData,
  workOrderData,
  operatorName,
}) => {
  const findUnitName =
    itemDetails?.item_units?.find(
      (el: any) => String(el?.id) === String(itemDetails?.selected_unit)
    )?.unit || unitName

  const tarQty = Number(data?.tar_quantity?.toFixed(2))

  return (
    <div
      ref={elRef}
      className={cn('d-flex flex-column text-body-small fw-semibold px-8 pb-0')}
    >
      <span className="text-center">{'SRI SAPTHAGIRI HI-TECH POLYMERS'}</span>
      <Divider className="my-2 border-black" dashed />
      {isMR ? (
        <MR
          data={data}
          billDate={billDate}
          findUnitName={findUnitName}
          tarQty={tarQty}
          itemDetails={itemDetails}
          operatorName={operatorName}
          workOrderData={workOrderData}
          jobData={jobData}
          isReturn={isReturn}
        />
      ) : (
        <GRN
          data={data}
          billDate={billDate}
          isReturn={isReturn}
          findUnitName={findUnitName}
          tarQty={tarQty}
          itemDetails={itemDetails}
        />
      )}
    </div>
  )
}

export default BatchCardPrint
