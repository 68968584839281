import { IGrn } from '@contracts/models/grn'
import { PaginatedResponse } from '@contracts/models/shared'
import { DELETE, GET, POST, PUT } from '@lib/AxiosClient'

export const getGrnBillNumInfo = (id: any): Promise<any> =>
  GET({
    url: `item/v2/get-bill-number/${id}/`,
  })

export const getPoList = (id: number, ids: any): Promise<any> =>
  GET({
    url: `invoice/grn-po/${id}/?ids=${ids}`,
  })
export const getGrnList = (
  signal: AbortSignal | undefined,
  params: {
    search?: string
    page: number
    page_size: number
    bill_type: string
  }
): Promise<PaginatedResponse<IGrn>> =>
  GET({ url: 'item/v2/grn/', params, signal })

export const grnCreate = (data: Partial<any>): Promise<any> =>
  POST({
    url: `item/v2/grn/`,
    data: data?.grnData,
  })
export const grnCreateStatus = (data: Partial<any>): Promise<any> => {
  return PUT({
    url: `item/v2/grn/${data?.data?.id}/`,
    data: data?.data?.data,
  })
}

export const getGrn = (id: number): Promise<any> => {
  return GET({
    url: `item/v2/grn/${id}/`,
  })
}
export const getGrnBill = (id: number): Promise<any> => {
  return GET({
    url: `item/v2/grn-report/${id}/`,
  })
}

export const getStockDataById = (id: number): Promise<any> =>
  GET({ url: `item/v2/grn/${id}/` })

export const deleteEachItem = ({ id }: { id: number }): Promise<any> =>
  DELETE({
    url: `item/v2/grn-item/${id}/`,
  })

export const deleteStockEntry = ({ id }: { id: number }): Promise<any> =>
  DELETE({
    url: `item/v2/grn/${id}/`,
  })
