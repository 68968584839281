import { createQueryKeys } from '@lukemorales/query-key-factory'

export const materialEntryQueries = createQueryKeys('materialEntry', {
  getNumber: null,
  list: (page_size, search, orgId) => [page_size, search, orgId],
  wo_list: (search, ids) => [search, ids],
  get_me: (id) => [id],
  get_me_bill: (id) => [id],
  job: (id) => [id],
})
