import { mergeQueryKeys } from '@lukemorales/query-key-factory'

import { masterQueries } from './master'
import { orgQueries } from './org'
import { vendorQueries } from './vendor'
import { itemsQueries } from './items'
import { grnQueries } from './grn'
import { materialQueries } from './materials'
import { dynamicQueries } from './dynamic'
import { materialEntryQueries } from './materialEntry'
import { reportQueries } from './report'

export const refKeyOnOrgChng = [
  'org',
  'master',
  'vendor',
  'items',
  'grn',
  'materials',
  'dynamic',
  'materialEntry',
  'report',
]

export const queries = mergeQueryKeys(
  orgQueries,
  masterQueries,
  vendorQueries,
  itemsQueries,
  grnQueries,
  materialQueries,
  dynamicQueries,
  materialEntryQueries,
  reportQueries
)
