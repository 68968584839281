import { materialReturnStatus } from '@constants/index'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  Button,
  Drawer,
  SearchInput,
  SwitchTab,
  Tab,
  Table,
} from 'yolo-design/components'
import { Add, Print } from 'yolo-design/icons'
import CreateReturn from './CreateReturn'
import { useMaterialReturnList } from '@services/material-return'
import cn from 'classnames'
import BatchCardPrint from '@components/grn/Components/BatchCardPrint'
import dayjs from 'dayjs'
import { useReactToPrint } from 'react-to-print'
import { ViewMaterialIssueListItemBatch } from '@contracts/models/materials-return'

const MaterialReturn = () => {
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const routeNavigate = useRouteNavigate()
  const [printDetails, setPrintDetails] =
    useState<ViewMaterialIssueListItemBatch>()
  const printRef = useRef(null)

  const searchValue = searchParams.get('search') || ''
  const activeTabId = searchParams.get('tab') || materialReturnStatus[0].key

  const { list, isLoading, refetch, lastItemRef } = useMaterialReturnList(
    {},
    {
      page_size: 10,
      status: activeTabId === 'all' ? undefined : activeTabId,
      search: searchValue,
    }
  )

  const handleSearch = (text: string) => {
    searchParams.set('search', text)
    setSearchParams(searchParams)

    if (text.length <= 0) {
      searchParams.delete('searchMI')
      setSearchParams(searchParams)
    }
  }

  const handleTabChange = (key: string) => {
    searchParams.set('tab', key)
    setSearchParams(searchParams)
  }

  const printCard = useReactToPrint({
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',
    content: () => printRef.current,
    onAfterPrint: () => {
      setPrintDetails(undefined)
    },
    nonce: String(new Date().getMilliseconds()),
  })

  const columns = [
    {
      title: 'Material',
      dataIndex: 'product_name',
      render: (val: any, column: any, key: number) => (
        <div
          ref={list?.length || []?.length - 1 === key ? lastItemRef : null}
          className="d-flex flex-column"
        >
          <span>{val}</span>
          <span className="text-label-extra-small mt-4 text-grey-600">
            {column.product_sku_code}
          </span>
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'fully_utilized',
      width: '5%',
      render: (val: any, column: any) => (
        <Button
          size="small"
          icon={Print}
          type="outlined"
          antdButtonProps={{
            onClick: () => {
              setPrintDetails(column)
            },
            disabled: val,
          }}
        >
          Print
        </Button>
      ),
    },
    { title: 'Batch Id', dataIndex: 'barcode' },
    { title: 'Issue No.', dataIndex: 'issue_number' },
    {
      title: 'Issued Stock',
      dataIndex: 'issue_stocks',
      render: (val: string, column: any) =>
        val ? `${val} ${column.unit_name}` : '-',
    },
    {
      title: 'Used Stock',
      dataIndex: 'used_stocks',
      render: (val: string, column: any) =>
        val ? `${val} ${column.unit_name}` : '-',
    },
    {
      title: 'Return Stock',
      dataIndex: 'return_stocks',
      render: (val: string, column: any) =>
        val ? `${val} ${column.unit_name}` : '-',
    },
    {
      title: 'Type',
      dataIndex: 'fully_utilized',
      render: (val: boolean) => (
        <span
          className={cn(
            'px-8 py-2 d-flex align-items-center w-max-content rounded-4 text-label-small fw-bold',
            {
              'bg-washed-purple': val,
              'bg-washed-green': !val,
            }
          )}
        >
          {!val ? 'RETURNED' : 'UTILIZIED'}
        </span>
      ),
    },
  ]

  useEffect(() => {
    if (printDetails) {
      printCard({})
    }
  }, [printDetails])

  return (
    <>
      <div className="w-100 p-24">
        <div className="d-flex justify-content-between w-100 ">
          <div style={{ width: '540px' }}>
            <SwitchTab
              tabItems={[
                { key: 'material-issue', label: 'Issue' },
                { key: 'material-return', label: 'Return' },
              ]}
              selectedKey={pathname.split('/')[2]}
              onClick={(key) => {
                routeNavigate(key, { addOrg: true })
              }}
            />
          </div>
          <div className="d-flex ">
            <SearchInput
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
              className="me-16"
              placeholder="Search"
            />
            <Button
              label="Return Material"
              antdButtonProps={{
                onClick: () => {
                  setDrawerOpen(true)
                },
              }}
              size="large"
              icon={Add}
            />
          </div>
        </div>
        <Tab
          className="w-100 mt-12"
          items={materialReturnStatus}
          activeKey={activeTabId}
          onChange={handleTabChange}
        />
        <Table
          columns={columns}
          dataSource={list || []}
          rowKey={'id'}
          loading={isLoading}
        />
      </div>
      <Drawer
        open={drawerOpen}
        destroyOnClose
        closable
        onClose={() => setDrawerOpen(false)}
      >
        <CreateReturn listRefetch={refetch} />
      </Drawer>

      <div className="d-none">
        <BatchCardPrint
          isMR={printDetails?.is_mr}
          billDate={dayjs(printDetails?.return_date)}
          data={{
            tar_quantity: printDetails?.tar_quantity,
            batch_quantity: printDetails?.batch_quantity,
            gross_quantity: printDetails?.gross_quantity,
            batch_number: printDetails?.barcode,
            micron: printDetails?.micron,
            material_size: printDetails?.material_size,
          }}
          itemDetails={{
            selected_unit: printDetails?.unit_id,
            name: printDetails?.product_name,
          }}
          elRef={printRef}
          isReturn
          unitName={printDetails?.unit_name}
          operatorName={printDetails?.operator_name}
        />
      </div>
    </>
  )
}

export default MaterialReturn
