import { ENV } from '@config/app.config'
import { useParams } from 'react-router-dom'

const Purchase = () => {
  const { orgId } = useParams()

  return (
    <div className="d-flex w-100 flex-column">
      <iframe
        key={`purchase`}
        className="w-100 h-100 border-0"
        src={ENV.MANUFACTURER_WEB_BASE_URL + `/${orgId}/purchase-indent/frame`}
      />
    </div>
  )
}

export default Purchase
