import dayjs from 'dayjs'
import React from 'react'
import { Table, Text } from 'yolo-design/components'

const IssueReport = ({
  data,
  printRef,
  date,
  product,
}: {
  data: any
  printRef: any
  date: any
  product: any
}) => {
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: '5%',
      render: (_: any, rec: any, i: number) => i + 1,
    },

    {
      title: 'Material ',
      dataIndex: 'product_name',
    },
    {
      title: 'Sku Code',
      dataIndex: 'product_sku_code',
    },
    {
      title: 'Batch',
      dataIndex: 'tracking_id',
    },
    {
      title: 'Issue Qty',
      dataIndex: 'issue_stocks',
    },
    {
      title: 'Issue Date',
      dataIndex: 'issue_date',
      render: (rec: any) => {
        const val = dayjs(rec).format('DD-MMM-YY')
        return <>{val}</>
      },
    },
  ]
  return (
    <div ref={printRef} className="w-100 m-12 ">
      <div className="d-flex w-100 justify-content-center align-items-center">
        <Text
          category="heading"
          value="Material Issue Report"
          weight="semibold"
        />
      </div>

      <div className="d-flex mt-24 w-100 gap-4 justify-content-center align-items-center">
        <div className="border border-1x p-4 rounded-4">
          <Text category="title" value={`Date: ${date}`} weight="semibold" />
        </div>
        <div className=" border border-1x p-4 rounded-4">
          <Text
            category="title"
            value={`Product: ${product}`}
            weight="semibold"
          />
        </div>
      </div>
      <div className="mt-12">
        <Table columns={columns} dataSource={data || []} />
      </div>
    </div>
  )
}

export default IssueReport
