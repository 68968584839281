// import { isStringRepeated } from '@constants/index'
// import { useDeleteItemTracking, useRecreateItemTracking } from '@services/items'
import dayjs from 'dayjs'
import { FC, useEffect, useRef, useState } from 'react'
import {
  // Button,
  FormInput,
  FormItem,
  Text,
  // Tooltip,
  FormInputDate as YoloDate,
} from 'yolo-design/components'
// import { Color } from 'yolo-design/constants'
// import { Add, Cross, Print } from 'yolo-design/icons'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
// import { restrictToNumbersAndDots } from '@utils/validation'
import { useReactToPrint } from 'react-to-print'
// import BatchCardPrint from './BatchCardPrint'
// import { useSearchParams } from 'react-router-dom'
// import { useOperatorsList } from '@services/org'
// import { useGetJobDetail, useWoList } from '@services/materialEntry'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
interface IProps {
  form: any
  fields: any
  // add: any
  // remove: any

  // itemData: any
  // billDate: any
  // error: any
  // setQuery: any
  // grnNo: string
}
const BatchCard: FC<IProps> = ({ form, fields }) => {
  // const [selectCard, setSelectCard] = useState<any>()
  // const [searchParams] = useSearchParams()
  // const operatorId = searchParams.get('operator') || ''
  // const workOrderId = searchParams.get('work_order') || ''
  // const jobId = searchParams.get('job') || ''
  // const allowPrint = isMR ? !!operatorId && !!workOrderId && !!jobId : true
  const [billData, setBillData] = useState<any>()
  const printRef = useRef(null)

  // const { data: operatorsList } = useOperatorsList()

  // const { data: woList } = useWoList({}, { search: '' })

  // const { data: jobData } = useGetJobDetail({ enabled: !!jobId }, { id: jobId })

  // const workOrderData = woList?.data?.find((f) => f.id === workOrderId)

  // const operatorData = operatorsList?.find(
  //   (f) => String(f.id) === String(operatorId)
  // )

  // const postItemTrack = useRecreateItemTracking({
  //   onSuccess: (res) => {
  //     add()
  //     const batch_content_index =
  //       form.getFieldValue('batch_numbers')?.length - 1

  //     form.setFieldValue(
  //       ['batch_numbers', batch_content_index, 'batch_number'],
  //       res.item_tracking_id
  //     )

  //     form.setFieldValue(['batch_numbers', batch_content_index, 'id'], res.id)
  //   },
  // })

  // const deleteItemTrackEach = useDeleteItemTracking({
  //   onSuccess: () => {
  //     remove(selectCard)
  //     setSelectCard('')
  //   },
  // })

  const handlePrint = useReactToPrint({
    onAfterPrint: () => setBillData(undefined),
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',
    content: () => printRef.current,
  })
  // const handleGetWeight = async (e: any) => {
  //   if (reader) {
  //     try {
  //       const { value, done } = await reader.read()
  //       if (!done && value) {
  //         const decodedValue = new TextDecoder().decode(value).trim()
  //         if (decodedValue) {
  //           form.setFieldValue(`gross_qty_${e?.unique_id}`, decodedValue) // Set form field value
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error reading from serial port:', error)
  //       message.error('Failed to read data')
  //     }
  //   } else {
  //     message.error('Serial port is not connected')
  //   }
  // }
  useEffect(() => {
    if (billData) handlePrint({})
  }, [billData])

  // useEffect(() => {
  //   // Function to establish the serial port connection automatically
  //   const connectSerialPort = async () => {
  //     try {
  //       const newPort = await (navigator as any).serial.requestPort()
  //       await newPort.open({ baudRate: 9600 })

  //       const newReader = newPort.readable?.getReader()
  //       setPort(newPort)
  //       setReader(newReader)
  //     } catch (error) {
  //       message.error('Failed to connect to the weighing machine')
  //     }
  //   }
  //   connectSerialPort()

  //   return () => {
  //     // Cleanup: Close the port and release the reader when the component unmounts
  //     const closeSerialPort = async () => {
  //       if (reader) {
  //         reader.releaseLock()
  //       }
  //       if (port) {
  //         await port.close()
  //       }
  //     }
  //     closeSerialPort()
  //   }
  // }, [])

  return (
    <div>
      <>
        {fields?.map(
          ({ name, ...restField }: { name: number; restField: any }) => (
            <div key={name} className="mt-24 border-bottom">
              <div>
                <FormInput
                  {...restField}
                  name={[name, 'id']}
                  andtFormItemProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                />
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-2 align-items-center">
                    <Text
                      size="small"
                      weight="medium"
                      category="label"
                      value="Batch Number"
                      color="##1a1a1a"
                    />
                  </div>
                  {/* {name === 0 ? (
                    <></>
                  ) : (
                    <>
                      <Button
                        type="ghost"
                        label="Remove"
                        icon={Cross}
                        color={Color.indicators.error}
                        antdButtonProps={{
                          onClick: () => {
                            setSelectCard(name)
                            const batch_id = form.getFieldValue([
                              'batch_numbers',
                              name,
                              'id',
                            ])
                            if (batch_id) {
                              deleteItemTrackEach?.mutate({
                                id: batch_id,
                              })
                            } else {
                              remove(name)
                            }
                          },
                        }}
                      />
                    </>
                  )} */}
                </div>
                <FormItem>
                  <FormInput
                    // rules={[
                    //   () => ({
                    //     validator(_, value) {
                    //       if (String(value) === String(error)) {
                    //         return Promise.reject({
                    //           message: 'Batch Number is already taken',
                    //         })
                    //       }
                    //       return Promise.resolve()
                    //     },
                    //   }),
                    //   ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //       const batch_array = getFieldValue(
                    //         'batch_numbers'
                    //       )?.map((el: any) => el?.batch_number)

                    //       if (isStringRepeated(batch_array, value)) {
                    //         return Promise.reject({
                    //           message: 'Batch Number is already taken',
                    //         })
                    //       }
                    //       return Promise.resolve()
                    //     },
                    //   }),
                    //   {
                    //     required: true,
                    //     message: 'Batch Number is required',
                    //   },
                    //   {
                    //     // validateTrigger: (el) => console.log(el),
                    //     message: 'Batch Number is already taken',
                    //   },
                    // ]}
                    name={[name, 'batch_number']}
                    antdInputProps={{
                      // onChange: (event) => setQuery(event.target.value),
                      style: {
                        marginTop: '8px',
                      },
                    }}
                    showErrorMessage
                  />
                </FormItem>
              </div>
              <div className="d-flex justify-content-between w-100 gap-4 align-items-center">
                <div className="w-100">
                  <YoloDate
                    antdDatePickerProps={{
                      style: {
                        width: '100%',
                      },
                      format: 'MMMM D, YYYY',
                      placeholder: 'MM/DD/YYY',
                      onChange: () => {
                        form.setFieldsValue({
                          due_term: 'Select Due Period',
                          due_date: '',
                        })
                        form.setFieldValue(
                          ['batch_numbers', name, 'expiry_date'],
                          ''
                        )
                      },
                    }}
                    andtFormItemProps={{
                      validateTrigger: 'onChange',
                    }}
                    name={[name, 'manufactured_date']}
                    title={'Manufactured Date'}
                  />
                </div>
                <div className="w-100">
                  <YoloDate
                    antdDatePickerProps={{
                      disabledDate: (el: any) =>
                        dayjs(
                          form.getFieldValue([
                            'batch_numbers',
                            name,
                            'manufactured_date',
                          ])
                        ).isAfter(el, 'day'),
                      style: {
                        width: '100%',
                      },
                      format: 'MMMM D, YYYY',
                      placeholder: 'MM/DD/YYY',

                      onChange: () => {
                        form.setFieldsValue({
                          due_term: 'Select Due Period',
                          due_date: '',
                        })
                      },
                    }}
                    andtFormItemProps={{
                      validateTrigger: 'onChange',
                    }}
                    name={[name, 'expiry_date']}
                    title={'Expiry Date'}
                  />
                </div>
              </div>
              {/* <div className="d-flex justify-content-between gap-2">
                <FormItem>
                  <div className="d-flex flex-column">
                    <FormInput
                      name={[name, 'gross_quantity']}
                      title={'Gross Quantity'}
                      showErrorMessage={false}
                      antdInputProps={{
                        onInput: (e) => restrictToNumbersAndDots(e),
                      }}
                    />{' '}
                    <Button
                      label="Get Weight"
                      type="ghost"
                      icon={Secure}
                      antdButtonProps={{
                        onClick: () => handleGetWeight,
                        className: 'mt-8 mb-4',
                      }}
                    />
                  </div>
                </FormItem>
                <FormItem>
                  <FormInput
                    name={[name, 'batch_quantity']}
                    rules={[
                      {
                        required: true,
                        message: 'field is required',
                      },
                    ]}
                    title={'Net Quantity'}
                    antdInputProps={{
                      onInput: (e) => restrictToNumbersAndDots(e),
                    }}
                  />
                </FormItem>
                <FormItem shouldUpdate>
                  {() => {
                    const val = form.getFieldsValue()
                    const tar_quantity =
                      val?.batch_numbers?.[name]?.gross_quantity -
                      val?.batch_numbers?.[name]?.batch_quantity
                    const tar_value = tar_quantity > 0 ? tar_quantity : 0
                    return (
                      <>
                        <FormInput
                          {...restField}
                          andtFormItemProps={{ style: { display: 'none' } }}
                          name={[name, 'tar_quantity']}
                        />
                        <Text
                          category="label"
                          size="small"
                          weight="medium"
                          value={'Tar Qty'}
                          className=" mb-12"
                        />
                        <div
                          style={{
                            width: '100px',
                            border: '1px solid grey',
                            padding: '10px 0px',
                            backgroundColor: `${Color?.gray_shades?.gray_200}`,
                          }}
                          className="rounded-4 d-flex justify-content-start  overflow-scroll "
                        >
                          <Text
                            category="body"
                            size="medium"
                            weight="medium"
                            value={Number(tar_value).toFixed(2)}
                            className="mx-12"
                          />
                        </div>
                      </>
                    )
                  }}
                </FormItem>
              </div> */}
            </div>
          )
        )}
        {/* <FormItem shouldUpdate>
          {() => {
            const val = form.getFieldValue('batch_numbers')
            const batch_quantity = val
              ?.map((el: any) => el?.batch_quantity)
              ?.reduce((acc: any, cur: any) => Number(acc) + Number(cur), 0)

            return (
              <>
                {String(itemDetails?.quantity) === String(batch_quantity) ? (
                  <></>
                ) : (
                  <>
                    <Button
                      type="ghost"
                      antdButtonProps={{
                        loading: autoTracking
                          ? postItemTrack?.isLoading
                          : false,
                        onClick: () => {
                          const batchCreateData = {
                            unique_id: itemDetails?.unique_id,
                            data: {
                              item: itemDetails?.id,
                              stocks: Number(
                                Number(itemDetails?.quantity) -
                                  Number(batch_quantity)
                              ).toFixed(2),
                            },
                          }

                          if (autoTracking) {
                            postItemTrack?.mutate({
                              batchCreateData,
                            })
                          } else {
                            add()
                          }
                        },
                      }}
                      icon={Add}
                    >
                      Add Batch
                    </Button>
                  </>
                )}
              </>
            )
          }}
        </FormItem> */}

        <div></div>
      </>
    </div>
  )
}

export default BatchCard
