import { useStateList } from '@services/master'
import { stringIncludes } from '@utils/string'
import { Col } from 'antd'
import { FC } from 'react'
import {
  FormInput,
  FormInputPincode,
  FormSelect,
  Tooltip,
} from 'yolo-design/components'
import { Question } from 'yolo-design/icons'
import {
  formNameInputRestriction,
  sentenceCase,
  willCreateWhiteSpaceSequence,
} from 'yolo-design/utils'

type propTypes = {
  isShip?: boolean
}

const AddressForm: FC<propTypes> = ({ isShip }) => {
  const { data: stateList } = useStateList({}, {})

  const baseName = isShip ? 'shipping' : 'billing'

  return (
    <>
      <Col span={24}>
        <div className="d-flex mt-20 mb-16 align-items-center">
          <span className="text-body-medium fw-medium">
            {isShip ? 'Shipping' : 'Billing'} Address
          </span>
          <Tooltip
            placement="right"
            title={
              isShip
                ? 'A shipping address is the location where a company or a person prefers to have an order delivered.'
                : 'A billing address is an address at which a Company or a person receives a bill or other relevant documents.'
            }
          >
            <Question className="ms-4" size="small" weight="slim" />
          </Tooltip>
        </div>
      </Col>
      <Col span={12}>
        <FormInput
          antdInputProps={{ autoFocus: !isShip }}
          name={[baseName, 'address1']}
          placeHolder="Flat, House No, Building, Company, Appartment"
          title="Address Line 1"
          onKeyDown={(e) => {
            if (willCreateWhiteSpaceSequence(e)) {
              e.preventDefault()
            }
          }}
          rules={[
            {
              required: true,
              message: 'Address is Required!',
            },
            {
              min: 3,
              message: 'Address is too short!',
            },
            {
              max: 50,
              message: 'Address is too long!',
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <FormInput
          name={[baseName, 'address2']}
          placeHolder="Area, Street, Sector, Village"
          title="Address Line 2 (Optional)"
          onKeyDown={(e) => {
            if (willCreateWhiteSpaceSequence(e)) {
              e.preventDefault()
            }
          }}
          andtFormItemProps={{
            rules: [
              {
                min: 3,
                message: 'Address is too short!',
              },
              {
                max: 50,
                message: 'Address is too long!',
              },
            ],
          }}
        />
      </Col>
      <Col span={10}>
        <FormInput
          name={[baseName, 'city']}
          placeHolder="Eg: Chennai"
          title="City/Town"
          onKeyDown={formNameInputRestriction}
          onInput={(e) => (e.target.value = sentenceCase(e.target.value))}
          rules={[
            {
              required: true,
              message: 'City/Town is Required!',
            },
            {
              min: 3,
              message: 'City/Town is too short!',
            },
            {
              max: 20,
              message: 'City/Town is too long!',
            },
          ]}
        />
      </Col>
      <Col span={10}>
        <FormSelect
          name={[baseName, 'state']}
          placeHolder="Select From Option"
          title="State"
          optionsSelect={stateList || []}
          andtFormItemProps={{
            rules: [
              {
                required: true,
                message: 'Please Select State!',
              },
            ],
          }}
          antdSelectProps={{
            showSearch: true,
            filterOption: stringIncludes,
          }}
        />
      </Col>
      <Col span={4}>
        <FormInputPincode
          required
          name={[baseName, 'pincode']}
          placeHolder="000 000"
          title="Pincode"
        />
      </Col>
    </>
  )
}

export default AddressForm
