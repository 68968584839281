import { createQueryKeys } from '@lukemorales/query-key-factory'

export const grnQueries = createQueryKeys('grn', {
  getNumber: (id) => [id],
  list: (page_size, search, orgId, grn_type) => [
    page_size,
    search,
    orgId,
    grn_type,
  ],
  po_list: (vendorId, ids) => [vendorId, ids],
  get_grn: (id) => [id],
  get_grn_bill: (id) => [id],
  get_stock: (id) => [id],
})
