import { imgPlaceholder } from '@assets/index'
import React, { FC } from 'react'

const Image: FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> = ({ src, alt, ...props }) => {
  return (
    <img
      {...props}
      src={src || imgPlaceholder}
      alt={alt || ''}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = imgPlaceholder
      }}
    />
  )
}

export default Image
