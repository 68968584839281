import { FC } from 'react'

interface Props {
  name: string
}

const Tag: FC<Props> = ({ name }) => {
  return <p className="bg-grey-200 px-8 py-4 rounded-4">{name}</p>
}

export default Tag
