import { Drawer, Table } from 'yolo-design/components'

import { EmptyImg } from '@constants/index'
import dayjs from 'dayjs'
import { useState } from 'react'
import GrnView from '@components/grn/GrnView'
import { useSearchParams } from 'react-router-dom'
import { Tag } from 'antd'
const Grn = ({
  list,
  isLoading,
  lastItemRef,
  GrnRefetch,
}: {
  list: any
  isLoading: boolean
  lastItemRef: any
  GrnRefetch: any
}) => {
  const [viewGrn, sewtViewGrn] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const defaultColumns: any = [
    {
      title: 'GRN Date',
      dataIndex: 'bill_date',
      width: '10%',
      render: (text: string) => (
        <>{text ? dayjs(text).format('DD-MMM-YYYY') : '-'}</>
      ),
    },
    {
      title: 'GRN number',
      dataIndex: 'bill_number',
      render: (rec: string, _: any, key: number) => (
        <>
          <div
            ref={list?.length || []?.length - 1 === key ? lastItemRef : null}
            className="Product_Name_Class d-flex justify-content-between align-items-center"
          >
            <div className="d-flex align-items-center">
              <div>{rec}</div>
            </div>
          </div>
        </>
      ),
    },

    {
      title: ' Vendor Name',
      dataIndex: 'vendor',
      render: (text: string, rec: any) => {
        return (
          <div className="d-flex align-items-center">
            <img
              style={{
                width: '24px',
                marginRight: '12px',
                objectFit: 'contain',
                height: '24px',
              }}
              src={rec?.vendor_logo || EmptyImg}
              alt=""
            />
            {/* <Text className="showDetail" value={text} size="14px" weight="500" /> */}
            <div>{text}</div>
          </div>
        )
      },
    },
    {
      title: 'Purchase Orders',
      dataIndex: 'purchase_orders',
      width: '20%',
      render: (text: any) => {
        return (
          <>
            {' '}
            {text ? (
              <>
                {text?.map((po: any, index: any) => (
                  <Tag
                    key={index}
                    style={{
                      marginTop: '4px',
                      marginBottom: '4px',
                      lineHeight: '26px',
                      fontSize: '14px',
                    }}
                  >
                    {po}
                  </Tag>
                ))}
              </>
            ) : (
              <>-</>
            )}{' '}
          </>
        )
      },
    },
    {
      title: 'No Of Items',
      dataIndex: 'no_of_items',
      width: '10%',
      render: (text: string) => <>{text ? text : '-'}</>,
    },

    {
      title: 'Delivery Date',
      dataIndex: 'delivery_date',
      width: '10%',
      render: (text: string) => (
        <>{text ? dayjs(text).format('DD-MMM-YYYY') : '-'}</>
      ),
    },
    {
      title: <span className="">STATUS</span>,
      dataIndex: 'bill_status__name',
      render: (text: any, rec: any) => {
        return (
          <>
            <div
              className="spacer"
              style={{
                width: 'max-content',
                backgroundColor: rec?.color_code,
                borderRadius: '4px',
                padding: '2px 4px',
              }}
            >
              {text || '-'}
            </div>
          </>
        )
      },
      align: 'left',
      width: '10%',
    },
  ]

  return (
    <>
      <Table
        columns={defaultColumns}
        dataSource={list || []}
        rowKey={'id'}
        loading={isLoading}
        onRow={(data) => ({
          onClick: () => {
            // handleView(data)
            searchParams.set('billId', data?.id)
            setSearchParams(searchParams)
            sewtViewGrn(true)
          },
          style: { cursor: 'pointer' },
        })}
      />

      <Drawer
        width={800}
        placement="right"
        closable={false}
        open={viewGrn}
        customHeader={true}
        destroyOnClose={true}
      >
        <GrnView setOpen={sewtViewGrn} grnRefetch={GrnRefetch} />
      </Drawer>
    </>
  )
}
export default Grn
