import { useMutation, useQuery } from '@tanstack/react-query'
// import { UseMutationType, UseQueryType } from '@contracts/query'
import * as API from './api'
import {
  IBillsConfig,
  IInvitePost,
  IOperator,
  IOrg,
  IOrgList,
  ITempOrg,
  IUploadRes,
  IUserInfo,
} from '@contracts/models/org'
import { queries } from '@constants/queryKeys'
import {
  UseMutationType,
  UseQueryDataType,
  UseQueryType,
} from '@contracts/query'

export const useCreateTempOrg: UseMutationType<ITempOrg, Partial<ITempOrg>> = (
  options
) => useMutation(API.postTempOrg, options)

export const useInvite: UseMutationType<any, Partial<IInvitePost>> = (
  options
) => useMutation(API.postInvite, options)
export const useTempOrgInfo: UseQueryType<ITempOrg> = (options) =>
  useQuery({
    queryKey: queries.org.temp_info.queryKey,
    queryFn: API.getTempOrg,
    ...options,
  })

export const useOrgListInfo: UseQueryType<IOrgList[]> = (options) =>
  useQuery({
    queryKey: queries.org.list.queryKey,
    queryFn: API.getOrgList,
    ...options,
  })

export const useOrgUserInfo: UseQueryType<IUserInfo> = (options) =>
  useQuery({
    queryKey: queries.org.user_info.queryKey,
    queryFn: API.getOrgUserInfo,
    ...options,
  })

export const useUpdateTempOrg: UseMutationType<
  ITempOrg,
  Partial<ITempOrg> & { id: number }
> = (options) => useMutation(API.putTempOrg, options)

export const useCreateOrg: UseMutationType<IOrg, { tempId: number }> = (
  options
) => useMutation(API.postCreateOrg, options)

export const useUpload: UseMutationType<IUploadRes, FormData> = (options) =>
  useMutation(API.postUpload, options)

export const useOrgInfo: UseQueryType<IOrg> = (options) =>
  useQuery({
    queryKey: queries.org.org_info.queryKey,
    queryFn: API.getOrgInfo,
    ...options,
  })

export const useOrgBillInfo: UseQueryType<IBillsConfig> = (options) =>
  useQuery({
    queryKey: queries.org.bills_info.queryKey,
    queryFn: API.getBillsInfo,
    ...options,
  })
export const useResend: UseQueryDataType<any, { id: string }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.org.resend(id).queryKey,
    queryFn: () => API.postResendInvite(id),
    ...options,
  })
export const useCreateOrgAddress: UseMutationType<any, Partial<any>> = (
  options
) => useMutation(API.orgAddressCreate, options)

export const useOperatorsList: UseQueryType<IOperator[]> = (options) =>
  useQuery({
    queryKey: queries.org.operators_list.queryKey,
    queryFn: API.getOperatorsList,
    ...options,
  })
