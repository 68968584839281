import { ENV } from '@config/app.config'

export const getCookieObject = () =>
  document.cookie
    .split('; ')
    .reduce((prev: Record<string, string>, current) => {
      const [name, ...value] = current.split('=')
      prev[name] = value.join('=')
      return prev
    }, {})

export const getCookie = (name: string) => getCookieObject()[name]
// export const getUserNameFromCookie = (name: string,) => getCookieObject()[name]

export const removeCookie = (name: string) => {
  document.cookie =
    name +
    `=; Path=/; domain=${ENV.WEB_DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const setPortCookie = (name: string, value: string, days: number) => {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`
}
export const setPCookie = (portInfo: any, days: number) => {
  if (portInfo.usbVendorId && portInfo.usbProductId) {
    const portId = `${portInfo.usbVendorId}-${portInfo.usbProductId}`
    setPortCookie('serialPortId', portId, days)
  }
}

export const getPortCookie = (name: string): string | null => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}
