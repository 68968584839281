import { ENV } from '@config/app.config'
import { useParams } from 'react-router-dom'

const DynamicItems = () => {
  const { formType, orgId } = useParams()

  return (
    <div className="d-flex w-100 flex-column">
      <iframe
        key={`items-${formType}`}
        className="w-100 h-100 border-0"
        src={
          ENV.INVOICE_WEB_BASE_URL +
          `/${orgId}/inventory/inventory-forms/${formType}`
        }
      />
    </div>
  )
}

export default DynamicItems
