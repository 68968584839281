import { ENV } from '@config/app.config'
import { IForm, IFormType } from '@contracts/models/dynamic'
import { GET } from '@lib/AxiosClient'

export const getAllFormTypes = (): Promise<IFormType[]> => {
  return GET({
    url: `${ENV.INVOICE_API_BASE_URL}item/inventory-form-type-config/`,
  })
}

export const getDForm = (params: { type?: string }): Promise<IForm[]> =>
  GET({ url: 'process/form/', params })
