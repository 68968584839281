import { Button, SearchInput, Tab } from 'yolo-design/components'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { Add } from 'yolo-design/icons'
import { useParams, useSearchParams } from 'react-router-dom'

import { useEffect, useState } from 'react'
//   import GrnView from '@components/grn/GrnView'
import { stockStatus } from '@constants/index'
import Grn from '@pages/GrnPage'
import MaterialStock from '@pages/MaterialStock'
import { useGetGrnList } from '@services/grn'
const Stock = () => {
  const [searchText, setSearcText] = useState<string>('')
  const { orgId = '' } = useParams()
  const routeNavigate = useRouteNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get('returnTab') || '1'
  const { list, isLoading, lastItemRef, refetch } = useGetGrnList(
    { staleTime: 0 },
    { page_size: 10, search: searchText, orgId, bill_type: tab }
  )

  const buttonName: any = {
    '1': 'Goods Receipt',
    '2': 'Material Inward',
  }
  const navigates: any = {
    '1': `create/stock-entry?returnTab=${tab}`,
    '2': `create/stock-entry?returnTab=${tab}`,
  }

  useEffect(() => {
    if (!tab) {
      searchParams.set('returnTab', '1')
      setSearchParams(searchParams)
    }
  }, [])

  return (
    <>
      <div className="w-100 px-24">
        <>
          <div className="d-flex  align-items-center justify-content-between  w-100 ">
            <div>
              <Tab
                className="w-100 mt-12"
                activeKey={tab}
                onChange={(el) => {
                  searchParams.set('returnTab', el)
                  setSearchParams(searchParams)
                }}
                items={stockStatus}
              />
            </div>
            <div className="d-flex align-items-center">
              <>
                <SearchInput
                  onChange={(e) => setSearcText(e.target.value)}
                  className="me-16"
                  placeholder="Search"
                />
                <Button
                  label={`New ${buttonName[tab]}`}
                  antdButtonProps={{
                    onClick: () =>
                      routeNavigate(navigates[tab], {
                        addOrg: true,
                      }),
                  }}
                  icon={Add}
                />
              </>
            </div>
          </div>
        </>

        <div className="mt-16">
          {tab === '1' ? (
            <>
              <Grn
                list={list}
                isLoading={isLoading}
                lastItemRef={lastItemRef}
                GrnRefetch={refetch}
              />
            </>
          ) : tab === '2' ? (
            <>
              <MaterialStock
                list={list}
                isLoading={isLoading}
                lastItemRef={lastItemRef}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}
export default Stock
