import { queries } from '@constants/queryKeys'
import {
  UseInfiniteQueryDataType,
  UseMutationType,
  UseQueryDataType,
} from '@contracts/query'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as API from './api'
import { IGrn } from '@contracts/models/grn'
import useInfiniteQueryRef from '@hooks/useInfiniteQueryRef'

export const useGrnBillNumberInfo: UseQueryDataType<any, { id: number }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.grn.getNumber(id).queryKey,
    queryFn: () => API.getGrnBillNumInfo(id),
    ...options,
  })
export const useGrnPoList: UseQueryDataType<
  any,
  { vendorId: number; ids: number }
> = (options, { vendorId, ids }) =>
  useQuery({
    queryKey: queries.grn.po_list(vendorId, ids).queryKey,
    queryFn: () => API.getPoList(vendorId, ids),
    ...options,
  })
export const useGetGrnList: UseInfiniteQueryDataType<
  IGrn,
  { search?: string; page_size?: number; orgId: string; bill_type: string }
> = (options, { search, page_size = 10, orgId, bill_type }) => {
  return useInfiniteQueryRef<IGrn>({
    queryKey: queries.grn.list(page_size, search, orgId, bill_type).queryKey,
    queryFn: ({ signal, pageParam }) => {
      return API.getGrnList(signal, {
        search,
        page: pageParam,
        page_size,
        bill_type,
      })
    },
    ...options,
  })
}
export const useCreateGrn: UseMutationType<any, Partial<any>> = (options) =>
  useMutation(API.grnCreate, options)
export const useCreateGrnStatus: UseMutationType<any, Partial<any>> = (
  options
) => useMutation(API.grnCreateStatus, options)

export const useRetriveGrn: UseQueryDataType<any, { id: number }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.grn.get_grn(id).queryKey,
    queryFn: () => API.getGrn(id),
    ...options,
  })

export const useGrnBill: UseQueryDataType<any, { id: number }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.grn.get_grn_bill(id).queryKey,
    queryFn: () => API.getGrnBill(id),
    ...options,
  })

export const useStockData: UseQueryDataType<any, { id: number }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.grn.get_stock(id).queryKey,
    queryFn: () => API.getStockDataById(id),
    ...options,
  })

export const useDeleteEachItem: UseMutationType<
  any,
  Partial<any> & { id: number }
> = (options) => useMutation(API.deleteEachItem, options)

export const useDeleteStockEntry: UseMutationType<
  any,
  Partial<any> & { id: number }
> = (options) => useMutation(API.deleteStockEntry, options)
