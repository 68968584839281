import { Form, Row } from 'antd'
import { FC, useState } from 'react'
import { Button, Checkbox, FormWrapper } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { ShortArrowLeft } from 'yolo-design/icons'
import AddressForm from './AddressForm'

export const AddAddressModal: FC<any> = ({
  setAddress,
  update,
  id,
  isVendor,
}) => {
  const [form] = Form.useForm()
  const [isChecked, setIsChecked] = useState(true)
  const handleFormSubmit = () => {
    const formValues = form.getFieldsValue()

    const Clientaddress = {
      shipping: {
        address1: isChecked
          ? formValues.billing?.address1
          : formValues.shipping?.address1,
        address2: isChecked
          ? formValues.billing?.address2
          : formValues.shipping?.address2,
        city: isChecked ? formValues.billing?.city : formValues.shipping?.city,
        country: 101,
        state: isChecked
          ? formValues.billing?.state
          : formValues.shipping?.state,
        pincode: isChecked
          ? formValues.billing?.pincode
          : formValues.shipping?.pincode,
      },
      billing: {
        address1: formValues.billing?.address1,
        address2: formValues.billing?.address2,
        city: formValues.billing?.city,
        country: 101,
        state: formValues.billing?.state,
        pincode: formValues.billing?.pincode,
      },
      same_as_shipping: isChecked,
    }
    const vendorAddress = {
      address1: formValues?.billing?.address1,
      address2: formValues?.billing?.address2,
      city: formValues?.billing?.city,
      country: 101,
      state: formValues?.billing?.state,
      pincode: formValues?.billing?.pincode,
    }
    const addressData = isVendor ? vendorAddress : Clientaddress

    update.mutate({ Data: addressData, id: id })
  }
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }
  return (
    <div className="d-flex w-100 h-100 flex-column">
      <div
        style={{ borderBottom: `1px solid ${Color.gray_shades.gray_400}` }}
        className="d-flex w-100 align-items-center h-max-content py-8 px-24 justify-content-between"
      >
        <Button
          antdButtonProps={{ onClick: () => setAddress(false) }}
          type="ghost"
          icon={ShortArrowLeft}
          size="large"
          label="Back"
        />

        <Button
          antdButtonProps={{
            onClick: () => form.submit(),
            loading: update.isLoading,
          }}
          color={Color.blue.just_blue}
          type="ghost"
          size="large"
          label={update.isLoading ? '' : 'Save Address'}
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          padding: '24px',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            maxWidth: '600px',
          }}
        >
          <FormWrapper
            antdFormProps={{
              form: form,
              layout: 'vertical',
              style: { width: '100%', marginTop: '16px' },
              onFinish: handleFormSubmit,
            }}
          >
            <Row gutter={[20, 0]} style={{ width: '100%' }}>
              <AddressForm />
              <div style={{ marginLeft: '12px' }}>
                {isVendor ? (
                  <></>
                ) : (
                  <>
                    <Checkbox
                      label={'Use Billing address as your Shipping Address'}
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  </>
                )}
              </div>
              {isChecked === true ? null : (
                <>
                  <AddressForm />
                </>
              )}
            </Row>
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}
