import { useEffect, useMemo, useState } from 'react'
import { MainLayout as Layout } from 'yolo-design/layouts'
import { businessAvatarThree, yoloInventoryLogo } from 'yolo-design/assets'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import {
  IdCard,
  LogOut,
  SaleReturn,
  Setting,
  Print,
  Inventory,
  Purchase,
} from 'yolo-design/icons'
import { Color } from 'yolo-design/constants'
import RouteLink from '@components/RouteLink'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { useOrgBillInfo, useOrgUserInfo } from '@services/org'
import { removeOrgId, removePasscode, setOrgId } from '@utils/auth'
import { useLogout } from '@services/user'
import { useFormTypes } from '@services/dynamic'

const MainLayout = () => {
  const routeNavigate = useRouteNavigate()
  // const navigate = useNavigate()
  const { orgId } = useParams()
  const [selectedMenu, setSelectedMenu] = useState('5')
  const { pathname } = useLocation()
  const { data: userInfo } = useOrgUserInfo()
  const { data: billInfo } = useOrgBillInfo()
  const logout = useLogout({
    onSuccess: () => {
      removePasscode(), removeOrgId(), (window.location.href = '/')
    },
  })

  const { data: formTypes = [] } = useFormTypes()

  const menuItems = useMemo(
    () => [
      ...(billInfo?.has_physical_stock
        ? [
            {
              key: '1',
              label: (
                <RouteLink addOrg to={`stock-entry`}>
                  Stock Entry
                </RouteLink>
              ),
              icon: Inventory,
              link: `stock-entry`,
            },
          ]
        : []),

      {
        key: '2',
        label: (
          <RouteLink addOrg to={`material-issue`}>
            Material Issue
          </RouteLink>
        ),
        icon: IdCard,
        link: `material-issue`,
      },

      // {
      //   key: 'material_inward',
      //   label: (
      //     <RouteLink addOrg to={`material-inward`}>
      //       Material Inward
      //     </RouteLink>
      //   ),
      //   icon: Inventory,
      //   link: `material-inward`,
      // },
      {
        icon: Purchase,
        link: `purchase`,
        key: 'purchase',
        label: (
          <RouteLink addOrg to={`purchase`}>
            Purchase Indent
          </RouteLink>
        ),
      },
      ...(formTypes || []).map((el: any) => ({
        key: el.id,
        label: (
          <RouteLink addOrg to={`items/${el.id}`}>
            {el.name}
          </RouteLink>
        ),
        icon: SaleReturn,
        link: el.id,
      })),
      {
        icon: Print,
        link: `report`,
        key: 'report',
        label: (
          <RouteLink addOrg to={`report`}>
            Report
          </RouteLink>
        ),
      },
    ],
    [billInfo, formTypes]
  )

  const handleOrgClick = (id: string) => {
    setOrgId(id)
    const pathArray = pathname.split('/')
    pathArray[1] = id
    routeNavigate(pathArray.slice(0, 3).join('/'))
  }
  // const currentOrg = userInfo?.organization?.find((f) => f.id === orgId)

  useEffect(() => {
    const rootPath = pathname.split('/')[2]
    setSelectedMenu(
      menuItems.find(
        (f) =>
          f.link === (rootPath === 'items' ? pathname.split('/')[3] : rootPath)
      )?.key || menuItems[0].key
    )
  }, [pathname, menuItems])

  return (
    <Layout
      logo={yoloInventoryLogo}
      onLogoClick={() => routeNavigate(``, { addOrg: true })}
      selectedOrganisationId={orgId}
      onAddOrgClick={() =>
        routeNavigate(`/org/create?orgId=${orgId}&redirectUrl=${pathname}`)
      }
      appMenu={{ appName: 'books', hide: [] }}
      organizationList={
        userInfo?.organization?.map((el: any) => ({
          id: el.id,
          name: el.name,
          logo: el?.logo || businessAvatarThree,
          gst: el.gst_num || 'Un Registered',
          onClick: () => handleOrgClick(el.id),
        })) || []
      }
      profileContent={{
        label: String(userInfo?.first_name),
        email: userInfo?.email,
        image: userInfo?.logo || businessAvatarThree,
      }}
      profileMenuList={[
        {
          label: 'Settings',
          icon: Setting,
          onClick: () => routeNavigate(`settings`, { addOrg: true }),
        },
        {
          label: 'Log out',
          icon: LogOut,
          color: Color.indicators.error,
          onClick: () => {
            logout.mutate({})
          },
        },
      ]}
      menuItems={menuItems}
      selectedMenu={selectedMenu}
    >
      <Outlet />
    </Layout>
  )
}

export default MainLayout
