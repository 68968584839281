import { FC } from 'react'
import { Button, Text } from 'yolo-design/components'
import { Error } from 'yolo-design/icons'

const AddAddressPopup: FC<any> = ({ type, setAddressPopup, setAddAddress }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <Text
        category="title"
        size="large"
        weight="medium"
        value={
          <div className="d-flex justify-content-center align-items-center">
            <Error className="pe-2" /> Attention
          </div>
        }
      />
      <Text
        category="body"
        size="medium"
        weight="regular"
        value={`${type} address is required to generate invoice`}
        className="mt-8 text-center"
      />
      <Button
        label="Add Address"
        type="outlined"
        antdButtonProps={{
          className: 'mt-16',
          onClick: () => {
            setAddressPopup(true)
            setAddAddress(true)
          },
        }}
      />
    </div>
  )
}

export default AddAddressPopup
