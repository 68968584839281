import { useDeleteStockEntry, useGrnBill } from '@services/grn'
import { Badge, Divider, Popconfirm, Skeleton } from 'antd'
import dayjs from 'dayjs'
import { FC, useRef } from 'react'
import { Button, Text } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { DoubleChevronRight, Edit, Print, Trash } from 'yolo-design/icons'
import { useReactToPrint } from 'react-to-print'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { useSearchParams } from 'react-router-dom'
import InwardBill from './Print/InwardBill'
interface IProps {
  setOpen: any
  grnRefetch: any
}
const GrnView: FC<IProps> = ({ setOpen, grnRefetch }) => {
  const routeNavigate = useRouteNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const billId = searchParams.get('billId')
  const { data: data, isLoading } = useGrnBill(
    { staleTime: 0 },
    { id: Number(billId) }
  )
  const deleteGrn = useDeleteStockEntry({
    onSuccess: () => {
      grnRefetch()
      searchParams.delete('billId')
      setSearchParams(searchParams)
      setOpen(false)
    },
  })
  const printRef = useRef(null)
  const handleEdit = () => {
    routeNavigate(`create/stock-entry/?returnTab=2&billId=${billId}`, {
      addOrg: true,
    })
  }
  const handlePrint = useReactToPrint({
    pageStyle: '@page { size: auto auto; margin: 4mm;}',
    documentTitle: data?.grn_number,
    content: () => printRef.current,
  })

  const billDate = dayjs(data?.bill_date).format('DD MMM, YYYY')

  return (
    <>
      {isLoading ? (
        <div className="p-24">
          <Skeleton style={{ height: '500px' }} active />
        </div>
      ) : (
        <>
          <div>
            {' '}
            <div
              style={{
                height: '53px',
                borderBottom: `1px solid ${Color?.gray_shades?.gray_400}`,
              }}
              className="d-flex justify-content-between align-items-center p-20"
            >
              <div>
                <Button
                  label="Close"
                  size="medium"
                  type="ghost"
                  color={Color?.background.dark}
                  iconPosition="right"
                  icon={DoubleChevronRight}
                  backgroundColor="white"
                  antdButtonProps={{ onClick: () => setOpen(false) }}
                />
              </div>
              <div className="d-flex gap-12"></div>
              <div className="d-flex align-items-center gap-12">
                <Button
                  color={Color?.blue?.just_blue}
                  icon={Print}
                  label="Print"
                  type="ghost"
                  antdButtonProps={{ onClick: handlePrint }}
                />
                <Divider className="m-0" type="vertical" />{' '}
                <Button
                  icon={Edit}
                  label="Edit"
                  type="ghost"
                  antdButtonProps={{ onClick: handleEdit }}
                />
                <Divider className="m-0" type="vertical" />
                <Popconfirm
                  title=""
                  style={{ width: '200px' }}
                  arrow={false}
                  trigger={['click']}
                  placement="top"
                  icon={false}
                  description={
                    <>
                      <div style={{ width: '200px' }}>
                        Are you sure you want to delete the invoice ?
                      </div>
                    </>
                  }
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{
                    loading: deleteGrn?.isLoading,
                  }}
                  onConfirm={() => {
                    deleteGrn?.mutate({ id: Number(billId) })
                  }}
                >
                  <Button
                    color={Color?.indicators?.error}
                    icon={Trash}
                    borderColor={Color?.indicators?.error}
                    label="Delete"
                    type="ghost"
                  />
                </Popconfirm>
              </div>
            </div>
            <div
              style={{
                padding: '0px 24px',
              }}
            >
              <div className="w-100 d-flex justify-content-center flex-column">
                {/* <div className="d-flex mt-12">
            <img
              src={data?.vendor_logo || businessAvatarFour}
              alt="yolo"
              style={{
                width: '40px',
                height: '40px',
                marginRight: '8px',
                objectFit: 'contain',
              }}
            />

            <div
              style={{
                marginLeft: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Text
                value={data?.vendor_address?.[0]?.vendor_name}
                category="title"
                weight="semibold"
                size="small"
              />
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Text
                  value={
                    data?.vendor_address?.[0]?.city
                      ? `${data?.vendor_address?.[0]?.city}`
                      : ''
                  }
                  category="label"
                  size="small"
                  weight="medium"
                />
              </div>
            </div>
          </div> */}
                {/* <div className='d-flex mt-12'>
            <Text value="Work Order:"/>
            <Text value={data?.work_orders[0]}/>

          </div> */}
                {/* <Divider className="p-0 m-8" /> */}
                <div className="d-flex mt-12 align-items-center justify-content-between w-100">
                  <Text
                    value={`#${data?.bill_number}`}
                    category="heading"
                    size="medium"
                    weight="semibold"
                  />
                </div>
                <Divider className="p-0 m-8" />

                <div className="d-flex gap-44 px-12">
                  <div className="d-flex flex-column justify-content-center ">
                    <Text
                      value={'Inward Date'}
                      category="label"
                      size="medium"
                      weight="bold"
                    />
                    <Text
                      value={billDate}
                      category="label"
                      size="medium"
                      weight="medium"
                    />
                  </div>

                  <div className="d-flex flex-column justify-content-center ">
                    <Text
                      value={'Operator Name'}
                      category="label"
                      size="medium"
                      weight="bold"
                    />
                    <Text
                      value={data?.operator_name || '-'}
                      category="label"
                      size="medium"
                      weight="medium"
                    />
                  </div>
                </div>
                {/* <Divider className="p-0 m-12" /> */}
              </div>
            </div>
            <div
              style={{ backgroundColor: '#fafafa' }}
              className="w-100 p-24 mt-12"
            >
              <Badge.Ribbon
                text={
                  <div className="text-black">{data?.bill_status_name}</div>
                }
                placement="start"
                color={data?.color_code}
              >
                <div className="mt-8 bg-white p-24 border">
                  <InwardBill data={data} elRef={printRef} />
                </div>
              </Badge.Ribbon>
            </div>
            <div className="d-none">
              <InwardBill data={data} elRef={printRef} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default GrnView
