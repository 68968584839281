import GrnDetails from '@components/grn/GrnDetails'
import MaterialEntryBasic from '@components/materialEntry/MaterialEntryBasic'
import { checkItemCondition } from '@constants/index'
import { useCreateMe, useMeBillNumberInfo } from '@services/materialEntry'
import { Col, Form, Row, message } from 'antd'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { yoloInventoryLogo } from 'yolo-design/assets'
import { Button, FormWrapper } from 'yolo-design/components'
const MaterialEntryInfo = () => {
  const [inputForm] = Form.useForm()
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const { orgId } = useParams()

  const [dataSource, setDataSource] = useState<any>([
    {
      unique_id: 'empty-object',
    },
  ])

  // API CALLS

  const { data: MeNumber } = useMeBillNumberInfo()

  const createGRN = useCreateMe({
    onSuccess: () => {
      navigate(`/${orgId}/material-entry`)
    },
  })

  const handleFinishPurchase = () => {
    const val = inputForm.getFieldsValue()
    const dateFormat = (val: any) => {
      return val?.format('YYYY-MM-DD')
    }
    const filteredDataSource = dataSource.filter(
      (f: any) => f.unique_id !== 'empty-object'
    )
    const isValidItems = checkItemCondition(filteredDataSource)

    const items = filteredDataSource?.map((el: any) => {
      return {
        unique_id: el?.unique_id,
        unit: el?.unit,
        type: el?.type,
        tar_quantity: el?.tar_quantity > 0 ? el?.tar_quantity : 0,
        net_quantity: el?.net_quantity,
        gross_quantity: el?.gross_quantity,
      }
    })

    const grnData = {
      entry_number: MeNumber?.material_entry_number,
      workorder_entity: val?.work_order || undefined,
      job_entity: val?.job_entity,
      bill_date: dateFormat(val?.bill_date),
      notes: val?.terms,
      items: items,
      operator: val?.operator,
    }

    if (isValidItems) {
      messageApi.open({
        type: 'error',
        content: "Some item's batch or serial numbers not created",
        duration: 2,
      })
    } else {
      createGRN?.mutate({ grnData })
    }
  }

  const isSaveButtonDisable = !!dataSource?.[0]?.unit

  //   const isVenAddress = vendorData?.address_detail?.city ? true : false
  const billDate = inputForm.getFieldValue('bill_date')
  return (
    <div>
      {contextHolder}
      <div className="d-flex flex-column w-100 h-100">
        <div className="d-flex w-100 h-max-content py-8 px-24 justify-content-between border-bottom border-1 border-grey-300">
          <img
            className="w-auto"
            style={{ height: '36px' }}
            src={yoloInventoryLogo}
            alt="inventory"
          />
          <div>
            <Button
              antdButtonProps={{
                htmlType: 'submit',
                disabled: !isSaveButtonDisable,
                loading: createGRN?.isLoading,
                onClick: () => {
                  inputForm.submit()
                },
              }}
              type="solid"
              label="Save and Send"
              size="medium"
            />
          </div>
        </div>
        <div className="d-flex w-100 h-100">
          <Row gutter={10} style={{ width: '100%' }}>
            <Col span={7}>
              <FormWrapper
                antdFormProps={{
                  onFinish: handleFinishPurchase,
                  form: inputForm,
                }}
              >
                <MaterialEntryBasic
                  grnNumber={MeNumber?.material_entry_number}
                  form={inputForm}
                />
              </FormWrapper>
            </Col>
            <Col span={16}>
              <FormWrapper
                antdFormProps={{
                  onFinish: handleFinishPurchase,
                  form: inputForm,
                }}
              >
                <GrnDetails
                  isMR
                  setDataSource={setDataSource}
                  dataSource={dataSource}
                  form={inputForm}
                  grnNo={MeNumber?.grn_number}
                  billDate={billDate}
                  type={2}
                />
              </FormWrapper>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default MaterialEntryInfo
