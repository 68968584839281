import { EmptyImg } from '@constants/index'
import { IItems } from '@contracts/models/items'
import { FC } from 'react'
import { Text } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
interface IProps {
  data: IItems
  removeBorder: boolean
}
const ListCard: FC<IProps> = ({ data, removeBorder }) => {
  return (
    <div
      style={{
        display: 'flex',
        borderBottom: removeBorder
          ? ' '
          : `1px solid ${Color?.gray_shades?.gray_300}`,
        paddingBottom: '8px',
      }}
    >
      <div style={{ height: '48px', width: '36px' }}>
        <img
          style={{
            height: '48px',
            width: '36px',
            objectFit: 'contain',
          }}
          src={data?.image || EmptyImg}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          marginLeft: '12px',
          height: '48px',
        }}
      >
        <div>
          <Text
            value={data?.name}
            className="text-truncate"
            category="body"
            size="medium"
            weight="medium"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRight: '1px solid gray',
              paddingRight: '8px',
            }}
          >
            <Text
              value={'HSN Code :'}
              category="label"
              size="small"
              weight="medium"
              color={Color?.gray_shades?.gray_700}
            />
            <Text
              value={data?.hsn_code}
              category="label"
              size="small"
              weight="medium"
              color={Color?.gray_shades?.gray_700}
            />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '8px',
            }}
          >
            <Text
              value={'SKU Code :'}
              category="label"
              size="small"
              weight="medium"
              color={Color?.gray_shades?.gray_700}
            />
            <Text
              value={data?.sku_code}
              category="label"
              size="small"
              weight="medium"
              color={Color?.gray_shades?.gray_700}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListCard
