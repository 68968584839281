import { FC } from 'react'
import { businessAvatarFive } from 'yolo-design/assets'
import { Text } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'

interface IProps {
  data: any
  removeBorder: boolean
}
const SelectPersonCard: FC<IProps> = ({ data, removeBorder }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: 'max-content',
        padding: '8px',
        borderBottom: removeBorder ? ' ' : '1px solid grey',
      }}
    >
      <div style={{ width: '44px', height: '44px' }}>
        <img
          style={{ width: '44px', height: '44px', objectFit: 'contain' }}
          src={data?.logo || businessAvatarFive}
        />
      </div>
      <div
        style={{
          marginLeft: '8px',
          display: 'flex',
          flexDirection: 'column',
          // alignItems: "center",
          justifyContent: 'space-around',
          height: '44px',
        }}
      >
        <div>
          <Text
            color={Color?.background?.dark}
            value={data?.display_name}
            category="title"
            size="medium"
            weight="semibold"
          />
        </div>
        <div>
          <Text
            color={Color?.gray_shades?.gray_600}
            value={
              data?.address_detail?.city && data?.address_detail?.address2
                ? `${data?.address_detail?.city} -
              ${data?.address_detail?.address2}`
                : data?.address_detail?.city || null
            }
            category="label"
            size="small"
            weight="medium"
          />
        </div>
      </div>
    </div>
  )
}

export default SelectPersonCard
