import useDebounce from '@constants/index'
import {
  useGetMaterialsArray,
  useGetReportIssueMaterial,
} from '@services/material-issue'
import { DatePicker, Select } from 'antd'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Button, Table, Text } from 'yolo-design/components'
import { Print } from 'yolo-design/icons'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import IssueReport from './print/IssueReport'

dayjs.extend(weekday)
dayjs.extend(localeData)
const MaterialIssueTable = () => {
  const [rangeDate, setRangeDate] = useState<any>([dayjs(), dayjs()])
  const [selectProduct, setSelectProduct] = useState<string>('')
  const printRef = useRef(null)
  const handlePrint = useReactToPrint({
    pageStyle: '@page { size: 270mm auto; margin: 4mm;}',
    content: () => printRef.current,
  })
  const { orgId = '' } = useParams()

  const [materialsSearch, setMaterialsSearch] = useState('')

  const { RangePicker } = DatePicker
  const fromDate = rangeDate?.[0].format('YYYY-MM-DD')
  const toDate = rangeDate?.[1].format('YYYY-MM-DD')
  const searchDebounced = useDebounce(materialsSearch, 500)

  const { list, isLoading: itemLoading } = useGetMaterialsArray(
    {},
    { search: searchDebounced, page_size: 30, orgId: orgId }
  )
  const productName =
    list?.find((el) => String(el?.id) === String(selectProduct))?.name || 'All'
  const date = fromDate
    ? `${dayjs(fromDate).format('DD-MM-YY')} to ${dayjs(toDate).format(
        'DD-MM-YY'
      )}`
    : 'All'
  const { data, isLoading } = useGetReportIssueMaterial(
    {},
    { orgId: orgId, from: fromDate, to: toDate, product: String(selectProduct) }
  )

  const rangePresets: any = [
    { label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
    {
      label: 'Yesterday',
      value: [
        dayjs().subtract(1, 'day').startOf('day'),
        dayjs().subtract(1, 'day').endOf('day'),
      ],
    },
    {
      label: 'This Week',
      value: [dayjs().startOf('week'), dayjs().endOf('week')],
    },
    {
      label: 'This Month',
      value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },

    {
      label: 'This Year',
      value: [dayjs().startOf('year'), dayjs().endOf('year')],
    },

    {
      label: 'Last Week',
      value: [
        dayjs().subtract(1, 'week').startOf('week'),
        dayjs().subtract(1, 'week').endOf('week'),
      ],
    },
    {
      label: 'Last Month',
      value: [
        dayjs().subtract(1, 'month').startOf('month'),
        dayjs().subtract(1, 'month').endOf('month'),
      ],
    },
  ]
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: '5%',
      render: (_: any, rec: any, i: number) => i + 1,
    },

    {
      title: 'Material ',
      dataIndex: 'product_name',
    },
    {
      title: 'Sku Code',
      dataIndex: 'product_sku_code',
    },
    {
      title: 'Batch',
      dataIndex: 'barcode',
    },
    {
      title: 'Issue Qty',
      dataIndex: 'issue_stocks',
    },
    {
      title: 'Issue Date',
      dataIndex: 'issue_date',
      render: (rec: any) => {
        const val = dayjs(rec).format('DD-MMM-YY')
        return <>{val}</>
      },
    },
  ]
  return (
    <div className="w-100 p-24">
      <div className="d-flex w-100 justify-content-center align-items-center">
        <Text
          category="heading"
          value="Material Issue Report"
          weight="semibold"
        />
      </div>
      <div className="d-flex mt-24 justify-content-between align-items-center">
        <div className="d-flex gap-12 justify-content-end align-items-center">
          <div className="">
            <RangePicker
              onChange={(el) => setRangeDate(el)}
              presets={rangePresets}
              size="large"
              className="w-max-content"
              defaultValue={[dayjs(), dayjs()]}
              allowClear={false}
            />
          </div>
          <Select
            style={{
              height: 40,
              width: '250px',
            }}
            allowClear
            filterOption={false}
            placeholder="Select a material"
            showSearch
            optionLabelProp="name"
            options={list?.map((item) => {
              return {
                label: (
                  <div className="d-flex flex-column justify-content-between align-items-start">
                    <span className="fw-medium">{item.name}</span>
                    <span className="text-label-small mt-2 text-grey-500 fw-medium">
                      {item.sku_code}
                    </span>
                  </div>
                ),
                value: item.id,
                name: item.name,
              }
            })}
            onSearch={(e) => {
              setMaterialsSearch(e)
            }}
            onClear={() => {
              setSelectProduct('')
              setMaterialsSearch('')
            }}
            onSelect={(e) => {
              setSelectProduct(e)
              setMaterialsSearch('')
            }}
            loading={itemLoading}
          />
        </div>
        <div className="d-flex  gap-4 justify-content-end align-items-center">
          <Button
            label="Print"
            icon={Print}
            antdButtonProps={{ onClick: handlePrint }}
          />
        </div>
      </div>
      <div className="m-12">
        <Table
          scroll={{ y: 'calc(100vh - 250px)' }}
          columns={columns}
          dataSource={data || []}
          loading={isLoading}
        />
      </div>
      <div className="d-none">
        <IssueReport
          date={date}
          printRef={printRef}
          product={productName}
          data={data}
        />
      </div>
    </div>
  )
}

export default MaterialIssueTable
