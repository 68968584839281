import MainLayout from '@components/Layout/MainLayout'
import Loadable from '@components/Loadable'
import MaterialIssueTable from '@components/report/material-issue'
import MaterialReturnTable from '@components/report/material-return'
import StockReport from '@components/report/stock'

import useSentry from '@hooks/useSentry'
import Middleware from '@middlewares/index'
import DynamicItems from '@pages/DynamicItems'
import GrnInfo from '@pages/GrnPage/GrnInfo'
import MaterialIssue from '@pages/MaterialIssue'
import MaterialReturn from '@pages/MaterialReturn'
import MaterialEntryInfo from '@pages/MaterialStock/MaterialEntryInfo'
import Purchase from '@pages/Purchase'
import ReportTab from '@pages/reports'
import Stock from '@pages/StockEntry/Index'
import { useOrgBillInfo } from '@services/org'

// import Designation from '@pages/Desgination'

import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const Register = Loadable(lazy(() => import('@pages/User/Register')))
const Home = Loadable(lazy(() => import('@pages/Home')))
const CreateOrg = Loadable(lazy(() => import('@pages/Org/Create/index')))

const Routes = () => {
  const { sentryRoutes } = useSentry()
  const { data: billConfig } = useOrgBillInfo()

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Middleware />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: ':orgId',
          element: <MainLayout />,
          children: [
            {
              index: true,
              element: billConfig?.has_physical_stock ? (
                <Stock />
              ) : (
                <MaterialIssue />
              ),
            },
            {
              path: 'stock-entry',
              element: <Stock />,
            },

            {
              path: 'material-issue',
              element: <MaterialIssue />,
            },
            {
              path: 'material-return',
              element: <MaterialReturn />,
            },
            {
              path: 'report',
              element: <ReportTab />,
              children: [
                {
                  index: true,
                  element: <MaterialIssueTable />,
                },
                {
                  path: 'material-issue',
                  element: <MaterialIssueTable />,
                },
                {
                  path: 'material-return',
                  element: <MaterialReturnTable />,
                },
                {
                  path: 'stock',
                  element: <StockReport />,
                },
              ],
            },
            {
              path: 'report',
              element: <ReportTab />,
            },
            {
              path: 'purchase',
              element: <Purchase />,
            },
            {
              path: 'items/:formType',
              element: <DynamicItems />,
            },
          ],
        },

        {
          path: 'org/create',
          element: <CreateOrg />,
        },

        {
          path: ':orgId/create/stock-entry',
          element: <GrnInfo />,
        },

        {
          path: ':orgId/create/material-entry',
          element: <MaterialEntryInfo />,
        },
        {
          path: 'user/register',
          element: <Register />,
        },
      ],
    },
  ]

  return sentryRoutes(routes)
}

export default Routes
