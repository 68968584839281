import { createQueryKeys } from '@lukemorales/query-key-factory'

export const masterQueries = createQueryKeys('master', {
  country: null,
  state: (country) => [country],
  business_type: null,
  business_category: (type) => [type],
  registration_type: null,
  role: null,
  report: null,
  designationRole: null,
})
