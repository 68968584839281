import Image from '@components/Image'
import {
  useGetMaterialIssuedById,
  usePostReturnedMaterialById,
  useUtilizeIssuedMaterial,
} from '@services/material-return'
import { Skeleton, Spin } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { Cross } from 'yolo-design/icons'
import Item from './Item'
import BatchCardPrint from '@components/grn/Components/BatchCardPrint'
import { useReactToPrint } from 'react-to-print'
import dayjs from 'dayjs'
import { ViewMaterialIssueListItemBatch } from '@contracts/models/materials-return'

interface Props {
  setDrawerOpen: (value: boolean) => void
  name: string
  listRefetch: () => void
}

const MaterialEditDrawer: FC<Props> = ({ setDrawerOpen, listRefetch }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [printDetails, setPrintDetails] =
    useState<ViewMaterialIssueListItemBatch>()
  const id = searchParams.get('id')
  const printRef = useRef(null)
  const { data, isLoading, refetch } = useGetMaterialIssuedById(
    { enabled: !!id },
    { id: id || '' }
  )

  const closeDrawer = () => {
    setDrawerOpen(false)
    searchParams.delete('view')
    setSearchParams(searchParams)
  }

  const saveReturnMaterial = usePostReturnedMaterialById({
    onSuccess: (res) => {
      handlePrint(res)
      refetch()
      listRefetch()
    },
  })

  const updateUtilizeMaterial = useUtilizeIssuedMaterial({
    onSuccess: () => {
      refetch()
      listRefetch()
    },
  })

  const printCard = useReactToPrint({
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',
    content: () => printRef.current,
    onAfterPrint: () => {
      setPrintDetails(undefined)
    },
    nonce: String(new Date().getMilliseconds()),
  })

  const handlePrint = (data: ViewMaterialIssueListItemBatch) => {
    setPrintDetails(data)
  }

  useEffect(() => {
    if (printDetails) printCard({})
  }, [printDetails])

  return (
    <div className="w-100 h-100">
      <div
        style={{
          height: '53px',
          borderBottom: `1px solid ${Color?.gray_shades?.gray_400}`,
        }}
        className="d-flex justify-content-between align-items-center p-20"
      >
        <div>
          <Button
            label="Close"
            size="medium"
            type="ghost"
            color={Color?.background.dark}
            iconPosition="left"
            icon={Cross}
            backgroundColor="white"
            antdButtonProps={{
              onClick: () => {
                closeDrawer()
              },
            }}
          />
        </div>
      </div>

      {!isLoading ? (
        <>
          <div className="p-24">
            <div className="d-flex gap-8">
              <Image
                className=" border border-grey-300 p-8 rounded-8"
                height={120}
                width={92}
              />
              <div className="d-flex flex-column ms-12 justify-content-around">
                <span className="text-heading-small fw-semibold">
                  {data ? data.product_name : <Skeleton />}
                </span>
                <span className="text-title-small text-grey-700">
                  {data ? data.product_sku_code : <Skeleton />}
                </span>
              </div>
            </div>
            <div className="text-label-medium fw-medium mt-24 mb-16">
              <div
                className="d-flex justify-content-between border-bottom border-1 border-grey-100"
                style={{
                  padding: '10px 0',
                }}
              >
                <span className="text-grey-700">Recipient Name</span>
                <span className="text-black">
                  {data ? data.recipient : <Skeleton />}
                </span>
              </div>
              <div
                className="d-flex justify-content-between border-bottom border-1 border-grey-100"
                style={{
                  padding: '10px 0',
                }}
              >
                <span className="text-grey-700">Issuer Name</span>
                <span className="text-black">
                  {data ? data.issuer_name : <Skeleton />}
                </span>
              </div>
              <div
                className="d-flex justify-content-between border-bottom border-1 border-grey-100"
                style={{
                  padding: '10px 0',
                }}
              >
                <span className="text-grey-700">Issue Date</span>
                <span className="text-black">
                  {data ? (
                    new Date(data.issue_date).toLocaleDateString()
                  ) : (
                    <Skeleton />
                  )}
                </span>
              </div>
            </div>
            <div className="text-uppercase mb-16 text-label-extra-small fw-semibold text-grey-700">
              STOCK ISSUED
            </div>
            {data?.batch.map((item) => (
              <Item
                key={item.id}
                data={item}
                saveReturnMaterial={saveReturnMaterial}
                updateUtilizeMaterial={updateUtilizeMaterial}
                handlePrint={handlePrint}
              />
            ))}
          </div>
        </>
      ) : (
        <Spin
          spinning
          className="w-100 h-100 d-flex justify-content-center align-items-center position-absolute"
        />
      )}
      <div className="d-none">
        <BatchCardPrint
          isMR={printDetails?.is_mr}
          billDate={dayjs(printDetails?.return_date)}
          data={{
            batch_quantity: printDetails?.batch_quantity,
            gross_quantity: printDetails?.gross_quantity,
            tar_quantity: printDetails?.tar_quantity,
            batch_number: printDetails?.barcode,
            micron: printDetails?.micron,
            material_size: printDetails?.material_size,
          }}
          itemDetails={{
            selected_unit: printDetails?.unit_id,
            name: printDetails?.product_name,
          }}
          elRef={printRef}
          isReturn
          unitName={printDetails?.unit_name}
          jobData={printDetails?.job}
          workOrderData={printDetails?.work_order}
          operatorName={printDetails?.operator_name}
        />
      </div>
    </div>
  )
}

export default MaterialEditDrawer
