import { ENV } from '@config/app.config'
import Profile from '../../assets/images/svg/profile.svg'
import Empty from '../../assets/images/svg/Iteam Image Web.svg'
import { useEffect, useState } from 'react'
import {
  MaterialIssueStatus,
  MaterialReturnStatus,
  StockStatus,
} from '@contracts/materialIssue'
import { Building, SaleReturn } from 'yolo-design/icons'
export const TOKEN_KEY = 'Passcode'

export const ORG_KEY = 'OrgId'
export const DRAWER_WIDTH = 488
export const NESTED_DRAWER_WIDTH = 540
export const SIDE_LIST_WIDTH = '440px'
export const DRAWER_HEADER_HEIGHT = '52px'
export const SIGNIN_LINK = `${ENV.ACCOUNTS_WEB_BASE_URL}/signin/inventory`
export const IMAGE_EXTENSIONS = ['jpg', 'png', 'jpeg', 'webp']
export const Profiles = Profile
export const EmptyImg = Empty

// export const getUniqueId = (arr: IProduct[]) => {
//   const isEmpty = arr?.length === 0
//   const getId = arr?.map((el: any) => el?.cardId || 0)

//   if (isEmpty) {
//     return 1
//   } else {
//     return Math?.max(...getId) + 1
//   }
// }

export const giveUniqueId = (num: number) => {
  const isEmpty = num === 0

  if (isEmpty) {
    return 1
  } else {
    return num + 1
  }
}

export function isStringRepeated(arr: any, targetString: any) {
  let count = 0

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === targetString) {
      count++
      if (count > 1) {
        return true
      }
    }
  }

  return false
}

// see https://github.com/tannerlinsley/react-query/issues/293
// see https://usehooks.com/useDebounce/
export default function useDebounce(value: string, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export const getTrackingItems = (array1: any, array2: any) => {
  const combinedArray = array1.map((element: any) => {
    if (typeof element === 'number' || typeof element?.value === 'number') {
      const matchingObject = array2.find(
        (obj: any) => obj.id === element || obj.id === element?.value
      )
      return matchingObject
    } else {
      return {
        stocks: element?.stocks || 1,
        item_tracking_id: element?.label || element,
      }
    }
  })
  return combinedArray
}

export const checkItemCondition = (items: any) => {
  for (const item of items) {
    // Check if item object exists and has item_tracking property
    if (item && item.hasOwnProperty('item_tracking')) {
      // Check if item_tracking is equal to 3 or 2
      if (item.item_tracking === 3 || item.item_tracking === 2) {
        // Check if item_tracking_list length is 0
        if (item.item_tracking_list && item.item_tracking_list.length === 0) {
          return true // If any item satisfies the condition, return true
        }
      }
    }
  }
  // Return false if no item satisfies the condition
  return false
}

export const materialIssueStatus: Array<{
  key: MaterialIssueStatus | 'all'
  label: string
}> = [
  { key: 'all', label: 'All' },
  { key: '2', label: 'Issued' },
  { key: '4', label: 'Partially Returned' },
  { key: '3', label: 'Closed' },
]
export const stockStatus: Array<{
  key: StockStatus | '1'
  label: string
  icon: any
}> = [
  { key: '1', label: 'Goods Receipt', icon: Building },
  { key: '2', label: 'Material Inward', icon: SaleReturn },
]
export const materialReturnStatus: Array<{
  key: MaterialReturnStatus | 'all'
  label: string
}> = [
  { key: 'all', label: 'All' },
  { key: 'returned', label: 'Returned' },
  { key: 'utilized', label: 'Utilized' },
]
