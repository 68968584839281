import { IStockReport } from '@contracts/models/report'
import { useReportForm, useReportFormType } from '@services/reports'
import React, { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

const PrintFormat: FC<{ data?: IStockReport[] }> = ({ data }) => {
  const [searchParams] = useSearchParams()

  const formCode = searchParams.get('form') || ''
  const formTypeCode = searchParams.get('form_type') || ''

  const reportFormType = useReportFormType({}, Number(formCode))
  const reportForm = useReportForm({})

  const totalCount = data?.reduce(
    (acc, cur) => ({
      total_batch_count: acc.total_batch_count + cur.total_batch_count,
      available_batch_count:
        acc.available_batch_count + cur.available_batch_count,
      total_stock: acc.total_stock + cur.total_stock,
      issued_stock: acc.issued_stock + cur.issued_stock,
      stock: acc.stock + cur.stock,
    }),
    {
      total_batch_count: 0,
      available_batch_count: 0,
      total_stock: 0,
      issued_stock: 0,
      stock: 0,
    }
  )

  return (
    <div className="d-flex flex-column w-100">
      <table className="w-100 mt-4 text-label-small">
        <colgroup>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
        </colgroup>
        <thead>
          <tr>
            <td colSpan={10}>
              <span className="fw-medium">
                Stock Report as on {new Date().toLocaleString()}
              </span>
            </td>
            <td colSpan={10}>
              <div className="d-flex justify-content-end w-100">
                <span className="fw-medium">
                  Filters:{' '}
                  {reportForm?.data?.find((f) => String(f.code) === formCode)
                    ?.name || ''}
                  {', '}
                  {reportFormType?.data?.find(
                    (f) => String(f.value) === formTypeCode
                  )?.label || ''}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={20} height={4}></td>
          </tr>
          <tr>
            <td
              colSpan={1}
              className="fw-semibold border-bottom border-top border-dashed"
            >
              S No
            </td>
            <td
              colSpan={9}
              className="fw-semibold border-bottom border-top border-dashed"
            >
              Product Detail
            </td>
            <td
              colSpan={2}
              className="fw-semibold border-bottom border-top border-dashed"
            >
              Total
            </td>
            <td
              colSpan={2}
              className="fw-semibold border-bottom border-top border-dashed"
            >
              Avail
            </td>
            <td
              colSpan={2}
              className="fw-semibold border-bottom border-top border-dashed"
            >
              Gross Wt.
            </td>
            <td
              colSpan={2}
              className="fw-semibold border-bottom border-top border-dashed"
            >
              Iss Wt.
            </td>
            <td
              colSpan={2}
              className="fw-semibold border-bottom border-top border-dashed"
            >
              Stock
            </td>
          </tr>
        </thead>
        <tbody>
          {data?.map((el, i) => (
            <tr key={el.id}>
              <td colSpan={1}>{i + 1}</td>
              <td colSpan={9}>
                {el.name} Size: {el?.material_size} Micron: {el?.micron}{' '}
              </td>
              <td colSpan={2}>{el.total_batch_count}</td>
              <td colSpan={2}>{el.available_batch_count}</td>
              <td colSpan={2}>{el.total_stock}</td>
              <td colSpan={2}>{el.issued_stock}</td>
              <td colSpan={2}>{el.stock}</td>{' '}
            </tr>
          ))}
          <tr>
            <td colSpan={10} className="border-top border-dashed"></td>
            <td colSpan={2} className="border-top border-dashed">
              {totalCount?.total_batch_count?.toFixed(2)}
            </td>
            <td colSpan={2} className="border-top border-dashed">
              {totalCount?.available_batch_count?.toFixed(2)}
            </td>
            <td colSpan={2} className="border-top border-dashed">
              {totalCount?.total_stock?.toFixed(2)}
            </td>
            <td colSpan={2} className="border-top border-dashed">
              {totalCount?.issued_stock?.toFixed(2)}
            </td>
            <td colSpan={2} className="border-top border-dashed">
              {totalCount?.stock?.toFixed(2)}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={20} height={4}></td>
          </tr>
          <tr>
            <td colSpan={20} className="border-top border-dashed">
              <div className="d-flex">
                <span>Total Records: {data?.length}</span>
              </div>
            </td>
            {/* <td colSpan={2}>{totalCount?.total_batch_count?.toFixed(2)}</td>
            <td colSpan={2}>{totalCount?.available_batch_count?.toFixed(2)}</td>
            <td colSpan={2}>{totalCount?.total_stock?.toFixed(2)}</td>
            <td colSpan={2}>{totalCount?.issued_stock?.toFixed(2)}</td>
            <td colSpan={2}>{totalCount?.stock?.toFixed(2)}</td> */}
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default PrintFormat
