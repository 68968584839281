import { queries } from '@constants/queryKeys'
import { useQuery } from '@tanstack/react-query'
import * as API from './api'
import { UseQueryDataType, UseQueryType } from '@contracts/query'
import {
  IBussinessCategory,
  IBussinessType,
  ICountry,
  IRegistrationType,
  IRole,
  IState,
} from '@contracts/models/master'
import { MASTERS_STALE_TIME } from '@config/app.config'
import { MobileWithFlagText } from 'yolo-design/components'
import React from 'react'

export const useCountry: UseQueryType<ICountry[]> = (options) =>
  useQuery({
    queryKey: queries.master.country.queryKey,
    queryFn: API.getCountry,
    staleTime: MASTERS_STALE_TIME,
    ...options,
  })

export const useCountryWithFlag: () => {
  id: string
  label: React.ReactNode
}[] = () => {
  const { data = [] } = useCountry()

  return data.map((el) => ({
    id: String(el.id),
    label: (
      <MobileWithFlagText
        key={el?.id}
        flag={el?.emoji}
        text={String(el?.phone_code)}
      />
    ),
    phone_code: el.phone_code,
  }))
}

export const useStateList: UseQueryDataType<
  IState[],
  { countryId?: number }
> = (options, { countryId = 101 }) =>
  useQuery({
    queryKey: queries.master.state(countryId).queryKey,
    queryFn: () => API.getStateList({ countryId }),
    staleTime: MASTERS_STALE_TIME,
    select: (data) =>
      data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      })),
    ...options,
  })

export const useBusinessType: UseQueryType<IBussinessType[]> = (options) =>
  useQuery({
    queryKey: queries.master.business_type.queryKey,
    queryFn: API.getBusinessType,
    staleTime: MASTERS_STALE_TIME,
    select: (data) =>
      data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      })),
    ...options,
  })

export const useBusinessCategory: UseQueryDataType<
  IBussinessCategory[],
  { type?: number }
> = (options, params) =>
  useQuery({
    queryKey: queries.master.business_category(params.type).queryKey,
    queryFn: () => API.getBusinessCategory(params),
    staleTime: MASTERS_STALE_TIME,
    select: (data) =>
      data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      })),
    ...options,
  })
export const useReportList: UseQueryType<IRole[]> = (options) =>
  useQuery({
    queryKey: queries.master.report.queryKey,
    queryFn: API.getReportToList,
    staleTime: 0,
    select: (data) => [
      ...data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      })),
      {
        name: '',
        value: 0,
        label: 'Admin',
      },
    ],
    ...options,
  })
export const useRole: UseQueryType<IRole[]> = (options) =>
  useQuery({
    queryKey: queries.master.role.queryKey,
    queryFn: API.getRoleList,
    staleTime: MASTERS_STALE_TIME,
    select: (data) =>
      data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      })),
    ...options,
  })

export const useShareRole: UseQueryType<IRole[]> = (options) =>
  useQuery({
    queryKey: queries.master.role.queryKey,
    queryFn: API.getShareRoleList,
    staleTime: MASTERS_STALE_TIME,
    select: (data) =>
      data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      })),
    ...options,
  })
export const useDesignationRole: UseQueryType<IRole[]> = (options) =>
  useQuery({
    queryKey: queries.master.designationRole.queryKey,
    queryFn: API.getDesignationRoleList,
    select: (data) =>
      data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      })),
    ...options,
  })
export const useRegistrationType: UseQueryType<IRegistrationType[]> = (
  options
) =>
  useQuery({
    queryKey: queries.master.registration_type.queryKey,
    queryFn: API.getRegistrationType,
    staleTime: MASTERS_STALE_TIME,
    select: (data) =>
      data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      })),
    ...options,
  })
