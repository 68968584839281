import { queries } from '@constants/queryKeys'
import {
  UseInfiniteQueryDataType,
  UseMutationType,
  UseQueryDataType,
  UseQueryType,
} from '@contracts/query'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as API from './api'
import { IGrn, IJob, IWorkOrder } from '@contracts/models/grn'
import useInfiniteQueryRef from '@hooks/useInfiniteQueryRef'
import { PaginatedResponse } from '@contracts/models/shared'

export const useMeBillNumberInfo: UseQueryType<any> = (options) =>
  useQuery({
    queryKey: queries.grn.getNumber('f').queryKey,
    queryFn: API.getMeBillNumInfo,
    ...options,
  })
export const useWoList: UseQueryDataType<
  PaginatedResponse<IWorkOrder>,
  { search: string; ids: any }
> = (options, { search, ids }) =>
  useQuery({
    queryKey: queries.materialEntry.wo_list(search, ids).queryKey,
    queryFn: () => API.getWoList(search, ids),
    ...options,
  })
export const useGetMeList: UseInfiniteQueryDataType<
  IGrn,
  { search?: string; page_size?: number; orgId: string }
> = (options, { search, page_size = 10, orgId }) => {
  return useInfiniteQueryRef<IGrn>({
    queryKey: queries.materialEntry.list(page_size, search, orgId).queryKey,
    queryFn: ({ signal, pageParam }) => {
      return API.getMeList(signal, {
        search,
        page: pageParam,
        page_size,
      })
    },
    ...options,
  })
}
export const useCreateMe: UseMutationType<any, Partial<any>> = (options) =>
  useMutation(API.materialEntryCreate, options)
export const useRetriveMe: UseQueryDataType<any, { id: number }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.materialEntry.get_me(id).queryKey,
    queryFn: () => API.getmaterial(id),
    ...options,
  })

export const useGrnBill: UseQueryDataType<any, { id: number }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.grn.get_grn_bill(id).queryKey,
    queryFn: () => API.getGrnBill(id),
    ...options,
  })
export const useGetJobDetail: UseQueryDataType<IJob, { id: string }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.materialEntry.job(id).queryKey,
    queryFn: () => API.getJobbByWorkOrder(id),
    ...options,
  })
