import Image from '@components/Image'
import BatchCardPrint from '@components/grn/Components/BatchCardPrint'
import { ViewMaterialIssueListItemBatch } from '@contracts/models/materials-return'
import Item from '@pages/MaterialIssue/MaterialEditDrawer/Item'
import {
  useGetMaterialDetailsById,
  useGetMaterialsArray,
} from '@services/material-issue'
import {
  useIssuedItemBatches,
  usePostReturnedMaterialById,
  useUtilizeIssuedMaterial,
} from '@services/material-return'
import dayjs from 'dayjs'
import { FC, useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { SearchInput, Select } from 'yolo-design/components'
import { Sync } from 'yolo-design/icons'

const CreateReturn: FC<{ listRefetch: () => void }> = ({ listRefetch }) => {
  const [search, setSearch] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const { orgId = '' } = useParams()
  const [searchBatch, setSearchBatch] = useState('')
  const [printDetails, setPrintDetails] =
    useState<ViewMaterialIssueListItemBatch>()
  const printRef = useRef(null)

  const selectedMaterial = Number(searchParams.get('material')) || 0

  const materialsArray = useGetMaterialsArray(
    {},
    { search, page_size: 10, orgId: orgId }
  )

  const { data: materialDetail } = useGetMaterialDetailsById(
    { enabled: !!selectedMaterial },
    { id: selectedMaterial }
  )

  const { data: batchList, refetch } = useIssuedItemBatches(
    { enabled: !!selectedMaterial },
    { id: selectedMaterial, search: searchBatch }
  )

  const saveReturnMaterial = usePostReturnedMaterialById({
    onSuccess: (res) => {
      setPrintDetails(res)
      refetch()
      listRefetch()
    },
  })

  const handlePrint = (data: ViewMaterialIssueListItemBatch) => {
    setPrintDetails(data)
  }

  const printCard = useReactToPrint({
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',
    content: () => printRef.current,
    onAfterPrint: () => {
      setPrintDetails(undefined)
    },
    nonce: String(new Date().getMilliseconds()),
  })

  const updateUtilizeMaterial = useUtilizeIssuedMaterial({
    onSuccess: () => {
      refetch()
      listRefetch()
    },
  })

  useEffect(() => {
    if (printDetails) {
      printCard({})
    }
  }, [printDetails])

  return (
    <>
      <div className="d-flex flex-column ">
        {selectedMaterial ? (
          <div className="d-flex flex-column position-relative">
            <div className="d-flex gap-12 p-16 border-bottom border-grey-300">
              <Image
                className=" border border-grey-300 p-8 rounded-8"
                height={120}
                width={92}
              />
              <div className="d-flex flex-column justify-content-around">
                <div className="d-flex flex-column">
                  <span className="text-heading-small fw-semibold">
                    {materialDetail?.name || '-'}
                  </span>
                  <span className="text-title-small text-grey-700">
                    {materialDetail?.sku_code || '-'}
                  </span>
                </div>
                <div
                  className="d-flex justify-content-start align-items-center gap-2 w-max-content"
                  onClick={() => {
                    searchParams.delete('material')
                    setSearchParams(searchParams)
                  }}
                >
                  <span className="text-title-small text-grey-700 cursor-pointer">
                    Change Item
                  </span>
                  <Sync size="medium" weight="thin" cursor={'pointer'} />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-column position-sticky top-0 left-0  p-16 bg-white">
                <SearchInput
                  value={searchBatch}
                  placeholder="Search Item Batch"
                  onChange={(e) => setSearchBatch(e.target.value)}
                />
                <span className="text-label-small fw-medium mt-8">
                  No of batches issued: {batchList?.length || 0}
                </span>
              </div>
              <div className="d-flex flex-column px-16">
                {batchList?.length === 0 ? (
                  <span className="fw-semibold w-100 d-flex justify-content-center py-48">
                    No Batches were issued
                  </span>
                ) : (
                  <>
                    {batchList?.map((el) => (
                      <Item
                        key={el.batch_id}
                        data={el}
                        saveReturnMaterial={saveReturnMaterial}
                        updateUtilizeMaterial={updateUtilizeMaterial}
                        showIssueName
                        handlePrint={handlePrint}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex p-16 w-100">
            <Select
              className="w-100"
              size="large"
              filterOption={false}
              placeholder="Select a material"
              showSearch
              options={materialsArray.list?.map((item) => {
                return {
                  label: (
                    <div className="d-flex flex-column justify-content-between align-items-start">
                      <span className="text-text-label-large fw-medium">
                        {item.name}
                      </span>
                      <span className="text-label-extra-small">
                        {item.sku_code}
                      </span>
                    </div>
                  ),
                  value: item.id,
                }
              })}
              onSearch={(e) => {
                setSearch(e)
              }}
              onSelect={(e) => {
                searchParams.set('material', e)
                setSearchParams(searchParams)
                setSearch('')
              }}
              loading={materialsArray.isLoading}
            />
          </div>
        )}
      </div>

      <div className="d-none">
        <BatchCardPrint
          billDate={dayjs(printDetails?.return_date)}
          isMR={printDetails?.is_mr}
          data={{
            batch_quantity: printDetails?.batch_quantity,
            gross_quantity: printDetails?.gross_quantity,
            tar_quantity: printDetails?.tar_quantity,
            batch_number: printDetails?.barcode,
            micron: printDetails?.micron,
            material_size: printDetails?.material_size,
          }}
          itemDetails={{
            selected_unit: printDetails?.unit_id,
            name: printDetails?.product_name,
          }}
          elRef={printRef}
          isReturn
          unitName={printDetails?.unit_name}
          operatorName={printDetails?.operator_name}
        />
      </div>
    </>
  )
}

export default CreateReturn
