import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'
import { version } from '../../package.json'
import { Version } from 'yolo-design/components'

const initialValue = {
  value: 1,
  setValue: () => '',
}

type AppContextType = {
  value: number
  setValue: Dispatch<SetStateAction<number>>
}

const AppContext = createContext<AppContextType>(initialValue)

export const useAppContext = () => useContext<AppContextType>(AppContext)

const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const [value, setValue] = useState<number>(1)

  return (
    <AppContext.Provider value={{ value, setValue }}>
      <Version version={version} />
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
