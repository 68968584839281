import { Form } from 'antd'
import { FC, useEffect } from 'react'
import { Button, FormList, FormWrapper, Text } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { DoubleChevronRight } from 'yolo-design/icons'

import BatchCard from '../Components/BatchCard'
import dayjs from 'dayjs'
interface IProps {
  setOpen: any
  itemData: any
  setItemData: any
}
const BatchDrawer: FC<IProps> = ({ setOpen, setItemData, itemData }) => {
  const [form] = Form.useForm()

  // FORM SUBMIT
  const handleSubmitBatchNumbers = () => {
    const val = form.getFieldsValue()
    const values = val?.batch_numbers
    setItemData({
      ...itemData,
      batch_no: values?.[0]?.batch_number,
      manufactured_date: dayjs(values?.[0]?.manufactured_date),
      expiry_date: dayjs(values?.[0]?.expiry_date),
    })
    setOpen(false)
    // const unit_id = itemData?.units?.find(
    //   (el: any) => String(el?.id) === String(itemData?.selected_unit)
    // )?.unit_id

    // const batchTotalQuantity = val?.batch_numbers
    //   ?.map((eel: any) => Number(eel?.batch_quantity))
    //   ?.reduce?.((acc: any, cur: any) => acc + cur, 0)
    // const batch_numberss = val?.batch_numbers?.map((el: any) => {
    //   const dateFormat = (val: any) => {
    //     return val?.format('YYYY-MM-DD')
    //   }

    //   const tar_qauntity = el?.gross_quantity - el?.batch_quantity
    //   return {
    //     id: el?.id,
    //     tracking_id: el?.batch_number,
    //     item: itemData?.id,
    //     stocks: Number(el?.batch_quantity),
    //     manufactured_date: el?.manufactured_date
    //       ? dateFormat(el?.manufactured_date)
    //       : undefined,
    //     expiry_date: el?.expiry_date ? dateFormat(el?.expiry_date) : undefined,
    //     gross_quantity: Number(el?.gross_quantity),
    //     tar_quantity: tar_qauntity.toFixed(2),
    //   }
    // })
    // const manualData = {
    //   unique_id: itemData?.unique_id,
    //   item: itemData?.id,
    //   unit: unit_id,
    //   stocks: Number(itemData?.quantity).toFixed(2),
    //   item_tracking: batch_numberss,
    // }

    // if (Number(batchTotalQuantity) === Number(itemData?.quantity)) {
    //   // putItemTrack?.mutate({ data: manualData })
    // } else {
    //   messageApi.open({
    //     type: 'error',
    //     content:
    //       "Looks like there's a mismatch between the quantity entered and the quantity in all batches.",
    //     duration: 2,
    //   })
    // }
  }

  useEffect(() => {
    if (itemData?.batch_no)
      form.setFieldsValue({
        batch_numbers: [
          {
            batch_number: itemData?.batch_no ? itemData?.batch_no : undefined,
            manufactured_date: itemData?.manufactured_date
              ? dayjs(itemData?.manufactured_date)
              : undefined,
            expiry_date: itemData?.expiry_date
              ? dayjs(itemData?.expiry_date)
              : undefined,
          },
        ],
      })
  }, [])

  return (
    <div>
      <FormWrapper
        antdFormProps={{
          onFinish: handleSubmitBatchNumbers,
          validateTrigger: 'onBlur',
          form: form,
        }}
      >
        <div
          style={{
            height: '53px',
            borderBottom: `1px solid ${Color?.gray_shades?.gray_400}`,
          }}
          className="d-flex justify-content-between align-items-center p-20"
        >
          <div>
            <Button
              label="Close"
              size="medium"
              type="ghost"
              color={Color?.background.dark}
              iconPosition="right"
              icon={DoubleChevronRight}
              backgroundColor="white"
              antdButtonProps={{
                onClick: () => {
                  setOpen(false)
                },
              }}
            />
          </div>

          <Button
            label="Save Changes"
            size="medium"
            type="ghost"
            color={Color?.blue?.just_blue}
            backgroundColor="white"
            antdButtonProps={{
              onClick: () => form.submit(),
            }}
          />
        </div>
        <div
          style={{
            padding: '20px',
            overflowY: 'scroll',
            height: 'calc(100vh - 61px)',
            width: '100%',
          }}
        >
          <Text
            category="heading"
            size="medium"
            weight="semibold"
            value="Create Batch"
          />
          <FormList initialValue={[{ bacth_number: '' }]} name="batch_numbers">
            {(fields) => (
              <BatchCard
                // isMR={isMR}
                // itemData={itemData}
                // autoTracking={autoTracking}
                form={form}
                fields={fields}
                // add={add}
                // remove={remove}
                // error={error}
                // setQuery={setQuery}
                // billDate={billDate}
                // grnNo={grnNo}
              />
            )}
          </FormList>
        </div>
      </FormWrapper>
    </div>
  )
}

export default BatchDrawer
