import { createQueryKeys } from '@lukemorales/query-key-factory'

export const orgQueries = createQueryKeys('org', {
  temp_info: null,
  user_info: null,
  org_info: null,
  list: null,
  resend: (id) => [id],
  bills_info: null,
  operators_list: null,
})
