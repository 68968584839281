import { QUERY_CONFIG } from '@config/app.config'
import {
  QueryClient as ReactQueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { FC, PropsWithChildren, useState } from 'react'

const QueryClient: FC<PropsWithChildren> = ({ children }) => {
  const [queryClient] = useState(() => new ReactQueryClient(QUERY_CONFIG))
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export default QueryClient
