import { ENV } from '@config/app.config'
import { IVendorList } from '@contracts/models/vendor'
import { GET, PUT } from '@lib/AxiosClient'

export const getVendors = (
  signal: AbortSignal | undefined,
  params: {
    search?: string
    page_size?: number
    page?: number
  }
): Promise<IVendorList> =>
  GET({
    url: `${ENV.INVOICE_API_BASE_URL}vendor/vendor-list/`,
    params,
    signal,
  })

export const getVendorById = (selectId: number): Promise<any> =>
  GET({
    url: ENV.INVOICE_API_BASE_URL + `vendor/vendor-info/${selectId}/`,
  })
export const putVendorAddress = (data: Partial<any>): Promise<any> =>
  PUT({
    url: ENV.INVOICE_API_BASE_URL + `vendor/vendor-address/${data?.id}/`,
    data: data?.Data,
  })
